import { ExclamationCircleOutlined } from '@ant-design/icons';
import { BackButton } from '@components/shared/buttons/BackButton';
import { IsolatedModal } from '@components/shared/modals/IsolatedModal';
import { PharmacyFinder } from '@components/shared/modals/pharmacyFinder/PharmacyFinder';
import { ajaxByUser } from '@helper/api';
import { DateFormat } from '@helper/formatHelpers';
import { getEssityApiClient } from '@services/EssityApi';
import {
	CreateNoteResponseDto,
	OrderDto,
	OrderState,
	PharmacyDto,
	PharmacyTaxNoteCreateResponse,
	PharmacyTaxNoteDetailsDto,
	PharmacyTaxNoteUpdateResponse,
	TaxNoteSource,
} from '@services/src/models';
import {
	Button,
	Col,
	Modal,
	Row,
	Space,
} from 'antd';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import { Field, Formik, FormikActions, FormikProps } from 'formik';
import {
	FCheckbox,
	FDatePicker,
	FFieldLabel,
	FInput,
	FInputNumber,
	FTextArea,
} from 'forms/FormikFormItems';
import { showWarning } from 'layout/Modals';
import _ from 'lodash';
import moment, { Moment } from 'moment';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';

import { PharmacyNoteAddOrdersModal } from './addOrders/PharmacyNoteAddOrdersModal';
import styles from './styles/PharmacyNote.module.less';
import { PharmacyNoteValidationSchema } from './validation/PharmacyNoteValidationSchema';
import AttachmentUploader, { IAttachmentState } from '@components/shared/attachmentUploader/AttachmentUploader';

interface IProps {
	readOnly: boolean;
	noteId?: string;
	pharmacyId?: string;
	setAttachment: (attachment: Blob | undefined) => void;
	editMode?: boolean;
}


interface IState {
	noteDate?: Moment;
	noteNumber?: number;
	noteValue?: number;
	year?: Moment;
	month?: Moment;
	ordersValue?: number;
	settledOrdersValue?: number;
	isDocumentationComplete?: boolean;
	note?: string;
}

export const PharmacyNoteDetails = (props: IProps) => {
	const [pharmacy, setPharmacy] = useState<PharmacyDto>();
	const [orders, setOrders] = useState<OrderDto[]>([]);
	const [note, setNote] = useState<PharmacyTaxNoteDetailsDto>();
	const [attachments, setAttachments] = useState<IAttachmentState[]>();
	const [validationVisible, setValidationVisible] = useState<boolean>(false);
	const [suggestedOrders, setSuggestedOrders] = useState<OrderDto[]>();
	const [isBusy, setIsBusy] = useState<boolean>();
	const [limitedEditMode, setLimitedEditMode] = useState<boolean>(false);
	const [state, setState] = useState<IState>({
		year: moment(),
		month: moment(
			new Date(
				moment().year(),
				moment().add(-1, 'month').month(),
				moment().day()
			)
		),
		isDocumentationComplete: true,
	});

	const history = useHistory();

	useEffect(() => {
		if (props.noteId) {
			(async () => {
				const api = await getEssityApiClient();
				const result = await api.pharmacyTaxNote.getById(props.noteId!);
				setNote(result);
				setPharmacy(result.pharmacy);
				setLimitedEditMode(result.limitedEditMode!);
				const files = result.documentsData?.map((documentData, index) => {
					const blob = new Blob([
						new Uint8Array(
							documentData.data || new Uint8Array(),
							(documentData.data?.byteOffset ?? 0),
							documentData.data?.length ?? 0
						),

					]);
					return {
						id: documentData.id!,
						uid: documentData.id!,
						documentName: documentData.fileName,
						file: new File([blob], `${documentData.fileName}`, { type: documentData.mimeType }),
					} as IAttachmentState;
				});
				// if (files?.length && files?.length > 0)
				// 	props.setAttachment(files.map(file => file.file)[0]);

				setAttachments(files);
				setOrders(result.orders as OrderDto[]);
			})();
		}
	}, [props.noteId]);

	useEffect(() => {
		if (props.pharmacyId) {
			(async () => {
				const api = await getEssityApiClient();
				const result = await api.pharmacy.getById(props.pharmacyId!);
				setPharmacy(result);
			})();
		}
	}, [props.pharmacyId]);
	// useEffect(() => {
	// 	const files = attachments?.map((documentData, index) => {


	// 		return {
	// 			id: documentData.id!,
	// 			uid: documentData.id!,
	// 			name: documentData.name,
	// 			//file: new File([blob], `${index + 1}. ${documentData.fileName}`),
	// 			file: { ...documentData.file, name: `${index + 1}. ${documentData.name}` }
	// 		} as IAttachmentState;
	// 	});
	// 	if (files?.length == 1)
	// 		props.setAttachment(files.map(file => file.file)[0]);

	// 	setAttachments(files);
	// }, [attachments?.length]);
	useEffect(() => {
		(async () => {
			if (state.year && state.month) {
				const api = await getEssityApiClient();

				const result = await api.order.getAllNotAssignedOrdersForPharmacyInDateScope(
					{
						pharmacyId: pharmacy?.id,
						year: state.year!.year(),
						month: state.month!.month() + 1,
						includeOrdersAssignedToPharmacyTaxNoteId: undefined,
					}
				);

				setSuggestedOrders(result);
			}
		})();
	}, [state.month, state.year, pharmacy]);

	const getInitialValues = () => {
		if (note) {
			const dateObject = new Date(
				moment().year(),
				note.month!,
				moment().day()
			);

			return {
				pharmacyName: pharmacy?.name,
				placeId: pharmacy?.placeId,
				noteDate: moment(note?.noteDate),
				noteNumber: note?.noteNumber,
				noteValue: note?.noteValue,
				ordersValue: note?.assignedOrdersValue,
				settledOrdersValue: note?.settledOrdersValue,
				taxNoteType: note?.taxNoteType,
				month: moment(dateObject).add(-1, 'month'),
				year: moment(
					new Date(note!.year!, moment().month(), moment().day())
				),
				isDocumentationComplete: note?.isDocumentationComplete,
				note: note?.note,
			};
		} else {
			const month = moment(
				new Date(
					moment().year(),
					moment().add(-1, 'month').month(),
					moment().day()
				)
			);

			const year = moment();

			return {
				pharmacyName: pharmacy?.name,
				placeId: pharmacy?.placeId,
				month: state?.month ? state?.month : month,
				year: state?.year ? state.year : year,
				noteDate: state?.noteDate,
				noteNumber: state.noteNumber,
				noteValue: state.noteValue,
				ordersValue: state.ordersValue,
				isDocumentationComplete: state.isDocumentationComplete,
				note: state.note,
				settledOrdersValue: state?.settledOrdersValue,
			};
		}
	};

	const hasSelectedOrders = (): boolean => {
		if (!orders) {
			return false;
		}

		return orders.length > 0;
	};

	const isFieldDisabled = () => {
		return (
			(props.readOnly && !props.editMode) ||
			limitedEditMode ||
			(props.editMode && note?.taxNoteSource === TaxNoteSource.Portal)
		);
	};

	const RenderNoteDetails = (formikProps: FormikProps<any>) => {
		return (
			<>
				<Row>
					<Col span={24}>
						<Row>
							<Col span={10}>
								<AttachmentUploader
									attachments={attachments}
									setAttachments={(attachments) => setAttachments(attachments)}
									highlightSelectedItem={true}
									editDisabled={isFieldDisabled()}
									editMode={props.editMode}
									setAttachment={props.setAttachment}
									allowedExtensions={['pdf', 'jpg', 'jpeg', 'png', 'tiff', 'tif', 'bmp']}
									maxFileSizeMB={5}
								/>

							</Col>
						</Row>
					</Col>
				</Row>
				<Row>
					<Col span={1}>
						<Field
							component={FCheckbox}
							placeholder="Documentation complete"
							name="isDocumentationComplete"
							id="isDocumentationComplete"
							disabled={props.readOnly && !props.editMode}
							onChange={(event: CheckboxChangeEvent) => {
								setState({
									...state,
									isDocumentationComplete:
										event.target.checked,
								});
							}}
						/>
					</Col>
					<Col span={9}>
						<FFieldLabel
							label="Documentation complete"
							for="isDocumentationComplete"
						/>
					</Col>
				</Row>
				<Row>
					<Col span={24}>
						<FFieldLabel label="Pharmacy name" />
						<Row>
							<Col span={10}>
								<Field
									component={FInput}
									placeholder="Pharmacy name"
									name="pharmacyName"
									readOnly={true}
								/>
							</Col>
							<Col span={8} lg={5} offset={1} sm={5}>
								<PharmacyFinder
									readOnly={
										props.readOnly ||
										hasSelectedOrders() ||
										props.pharmacyId !== undefined
									}
									validatePlaceId={true}
									mode={'single'}
									style={{ width: '100%' }}
									onPharmacySelect={(
										pharmacies: PharmacyDto[]
									) => {
										if (pharmacies.length > 0) {
											setPharmacy(pharmacies[0]);
										}
									}}
								/>
							</Col>
						</Row>
					</Col>
				</Row>
				<Row>
					<Col span={10}>
						<FFieldLabel label="SAP ID" />
						<Field
							component={FInput}
							placeholder="SAP ID"
							name="placeId"
							readOnly={true}
						/>
					</Col>
				</Row>
				<Row>
					<Col span={10}>
						<FFieldLabel label="Note date" />
						<Field
							label="Note date"
							component={FDatePicker}
							name="noteDate"
							mode="date"
							style={{ width: '100%' }}
							disabled={isFieldDisabled()}
							changeData={(value: any) => {
								setState({
									...state,
									noteDate: value,
								});
							}}
						/>
					</Col>
				</Row>
				<Row>
					<Col span={10}>
						<FFieldLabel label="Note number" />
						<Field
							component={FInput}
							changeData={(value: any) => {
								setState({ ...state, noteNumber: value });
							}}
							placeholder="Note number"
							name="noteNumber"
							readOnly={isFieldDisabled()}
						/>
					</Col>
				</Row>
				<Row>
					<Col span={5} style={{ paddingRight: 5 }}>
						<FFieldLabel label="Month" />
						<Field
							component={FDatePicker}
							placeholder="Month"
							name="month"
							style={{ width: '100%' }}
							inputReadOnly={
								props.readOnly || hasSelectedOrders()
							}
							changeData={(value: any) => {
								setState({ ...state, month: value });
							}}
							picker="month"
							format={DateFormat.Month}
						/>
					</Col>
					<Col span={5} style={{ paddingLeft: 5 }}>
						<FFieldLabel label="Year" />
						<Field
							component={FDatePicker}
							placeholder="Year"
							style={{ width: '100%' }}
							name="year"
							inputReadOnly={
								props.readOnly || hasSelectedOrders()
							}
							changeData={(value: any) => {
								setState({ ...state, year: value });
							}}
							picker="year"
							format={DateFormat.Year}
						/>
					</Col>
				</Row>
				<Row>
					<Col span={10}>
						<FFieldLabel label="Note value" />
						<Field
							component={FInputNumber}
							placeholder="Note value"
							min={Number.MIN_SAFE_INTEGER}
							name="noteValue"
							readOnly={isFieldDisabled()}
							changeData={(value: number) => {
								setState({ ...state, noteValue: value });
							}}
						/>
					</Col>
				</Row>

				<Row>
					<Col span={10}>
						<FFieldLabel label="Orders value" />
						<Row>
							<Col span={12} style={{ paddingRight: 5 }}>
								<Field
									component={FInputNumber}
									placeholder="Orders value"
									name="ordersValue"
									min={Number.MIN_SAFE_INTEGER}
									readOnly={true}
								/>
							</Col>
							<Col span={12} style={{ paddingLeft: 5 }}>
								{pharmacy && (
									<PharmacyNoteAddOrdersModal
										readOnly={
											(props.readOnly &&
												!props.editMode) ||
											limitedEditMode
										}
										year={formikProps.values['year']}
										month={formikProps.values['month']}
										pharmacyId={pharmacy.id!}
										suggestedOrders={suggestedOrders}
										noteValue={
											formikProps.values['noteValue']
										}
										onOrdersSelect={(
											orders: OrderDto[]
										) => {
											setOrders(orders);

											const refundSum = _.sum(
												orders?.map((x) => x.refund!)
											);
											const refundSetteledSum = _.sum(
												orders?.filter(x => x.orderState == OrderState.SettledNhf).map((x) => x.refund!)
											);

											formikProps.setFieldValue(
												'ordersValue',
												refundSum.toFixed(2)
											);
											formikProps.setFieldValue(
												'settledOrdersValue',
												refundSum.toFixed(2)
											);

											setState({
												...state,
												ordersValue: refundSum,
												settledOrdersValue: refundSetteledSum,
											});
										}}
										selectedOrders={orders}
										pharmacyTaxNoteId={props.noteId}
									/>
								)}
							</Col>
						</Row>
					</Col>
				</Row>
				<Row>
					<Col span={10}>
						<FFieldLabel label="Setteled orders value" />
						<Row>
							<Col span={12} style={{ paddingRight: 5 }}>
								<Field
									component={FInputNumber}
									placeholder="Setteled orders value"
									name="settledOrdersValue"
									min={Number.MIN_SAFE_INTEGER}
									readOnly={true}
								/>
							</Col>

						</Row>
					</Col>
				</Row>
				<Row>
					<Col span={16}>
						<FFieldLabel label="Note" />
						<Field
							component={FTextArea}
							placeholder="Note"
							style={{ resize: 'none' }}
							maxLength={5000}
							name="note"
							rows={5}
							maxRows={5}
							changeData={(value: string) => {
								setState({ ...state, note: value });
							}}
							readOnly={props.readOnly && !props.editMode}
						/>
					</Col>
				</Row>
			</>
		);
	};

	const handleCreateOrUpdate = async (values: any, actions: any) => {
		if (
			Number(values.noteValue)?.toFixed(2) !==
			Number(values.settledOrdersValue)?.toFixed(2)
		) {
			setValidationVisible(true);
		} else {
			createOrUpdateNote(values);
		}
	};

	const createOrUpdateNote = async (values: any) => {
		const ordersIds = orders?.map((x) => x.id!);

		setIsBusy(true);

		if (!attachments || attachments.length === 0) {
			showWarning('Note attachment is required.');
			setIsBusy(false);
			return;
		}

		const files = await Promise.all(
			attachments.map(async (attachment) => {
				const data = await new Response(attachment.file).arrayBuffer();
				const fileExtension = attachment.file.name.split('.').pop();
				return {
					id: attachment.id,
					fileName: attachment.file.name,
					fileExtension: fileExtension,
					mimeType: attachment.file.type,
					data: new Uint8Array(data),
				};
			})
		);
		const notePayload = {
			pharmacyId: pharmacy?.id,
			noteDate: moment(values.noteDate).utc(true).toDate(),
			noteNumber: values.noteNumber,
			noteValue: values.noteValue,
			pharmacyTaxNoteOrders: ordersIds,
			isDocumentationComplete: values.isDocumentationComplete,
			note: values.note,
			documents: files,
		};

		if (props.editMode) {
			ajaxByUser('Successfully updated note.', () =>
				getEssityApiClient().then((api) =>
					api.pharmacyTaxNote
						.update({
							body: {
								...notePayload,
								pharmacyTaxNoteId: props.noteId,
							},
						})
						.then((response: PharmacyTaxNoteUpdateResponse) => {
							history.push(
								`/pharmacyPayments/${response.pharmacyTaxNoteGroupId}/${response.pharmacyId}`
							);
						})
						.finally(() => setIsBusy(false))
				)
			);
		} else {
			ajaxByUser('Successfully added note.', () =>
				getEssityApiClient().then((api) =>
					api.pharmacyTaxNote
						.create({
							body: {
								...notePayload,
								year: values.year.year(),
								month: values.month.month() + 1,
							},
						})
						.then((result: PharmacyTaxNoteCreateResponse) => {
							if (result) {
								const dto = result as CreateNoteResponseDto;
								const path = `/pharmacyPayments/${dto.pharmacyTaxNoteGroupId}/${dto.pharmacyId}`;

								if (dto.canBeConfirmedBy) {
									Modal.confirm({
										centered: true,
										maskClosable: true,
										content: `Dear user,
								
											Note you are trying to save has the same value as return note
											Do you want to confirm the return by note: ${dto.canBeConfirmedBy} ? `,
										title: `Confirmation`,
										onOk: () => {
											confirmValue(
												dto.pharmacyTaxNoteId!,
												path
											);
										},
										onCancel: () => {
											history.push(path);
										},
									});
								} else {
									Modal.success({
										centered: true,
										maskClosable: true,
										title: `Created new note with number: ${dto.pharmacyTaxNoteIdInternalNumber}`,
										onOk: () => {
											history.push(path);
										},
										onCancel: () => {
											history.push(path);
										},
									});
								}
							}
						})
						.finally(() => setIsBusy(false))
				)
			);
		}
	};

	const confirmValue = (pharmacyTaxNoteId: string, path: string): void => {
		setIsBusy(true);
		ajaxByUser('Successfully confirmed value.', () =>
			getEssityApiClient().then((api) =>
				api.pharmacyTaxNote
					.assignBalancedPaymentConfirmation({
						body: {
							pharmacyTaxNoteId: pharmacyTaxNoteId,
						},
					})
					.finally(() => {
						setIsBusy(false);
						history.push(path);
					})
			)
		);
	};

	return (
		<Space direction="vertical" className={styles.details}>
			<h2>Note Details</h2>
			{note?.internalNumber && (
				<h3>Pharmacy note {note?.internalNumber}</h3>
			)}
			<Space direction="vertical" className={styles.form}>
				<Formik
					validateOnChange={false}
					validateOnBlur={true}
					initialValues={getInitialValues()}
					enableReinitialize
					validationSchema={PharmacyNoteValidationSchema}
					onSubmit={(values, actions: FormikActions<any>) =>
						handleCreateOrUpdate(values, actions)
					}
					render={(formikProps: FormikProps<any>) => (
						<Space direction="vertical" style={{ width: '100%' }}>
							{RenderNoteDetails(formikProps)}
							<Space
								direction="horizontal"
								className={styles.actions}
							>
								<BackButton />
								<Button
									type="primary"
									shape="round"
									size="large"
									className={styles.save}
									disabled={
										(props.readOnly && !props.editMode) ||
										isBusy
									}
									onClick={() => formikProps.submitForm()}
								>
									Save
								</Button>
							</Space>
							<IsolatedModal
								title="Validation warning"
								visible={validationVisible}
								onOk={() => {
									setValidationVisible(false);
									createOrUpdateNote(formikProps.values);
								}}
								onCancel={() => {
									setValidationVisible(false);
								}}
								okText={
									props.editMode
										? 'Update note'
										: 'Create note'
								}
								cancelButtonProps={{ hidden: false }}
								okButtonProps={{ hidden: false }}
								cancelText="Back to edit"
							>
								<Space direction="horizontal">
									<ExclamationCircleOutlined />
									Value of eZWM orders is different than note
									value.
								</Space>
							</IsolatedModal>
						</Space>
					)}
				></Formik>
			</Space>
		</Space>
	);
};
