import { Button, Empty, Space } from 'antd';
import React, { useEffect, useRef, useState } from 'react';
import { RotateLeftOutlined, RotateRightOutlined, DownloadOutlined } from '@ant-design/icons';

import styles from './styles/PharmacyNote.module.less';

interface IProps {
	blob?: Blob;
}

export const PharmacyNotePreview = (props: IProps) => {
	if (!props.blob) {
		return (
			<Space direction="vertical" className={styles.preview}>
				<h2>Note View</h2>
				<div className={styles.viewer}>
					<Empty />
				</div>
			</Space>
		);
	}
	const getFileName = (blob: Blob): string => {
		if (blob instanceof File) {
			return blob.name;
		}
		return 'download';
	};
	console.log(props.blob);
	const url = URL.createObjectURL(props.blob);
	const isImage = props.blob.type.startsWith('image/');
	const isPdf = props.blob.type === 'application/pdf';

	return (
		<Space direction="vertical" className={styles.preview}>
			<h2>Note View</h2>
			<div className={styles.viewer}>
				{isImage ? (
					<ImageContainer url={url} name={getFileName(props.blob)} />
				) : isPdf ? (
					<iframe src={`/pdfjs/web/viewer.html?file=${url}`} width="100%" height="700px"></iframe>
				) : (
					<Empty />
				)}
			</div>
		</Space>
	);
};

interface ImageContainerProps {
	url: string;
	name: string;
}

const ImageContainer: React.FC<ImageContainerProps> = ({ url, name }) => {
	const [size, setSize] = useState(100);
	const [rotation, setRotation] = useState(0);
	const [imageUrl, setImageUrl] = useState(url);

	useEffect(() => {
		setImageUrl(url);
	}, [url]);

	const handleDownload = () => {
		const link = document.createElement("a");
		link.href = imageUrl;
		link.download = name;
		document.body.appendChild(link);
		link.click();
		document.body.removeChild(link);
	};

	return (
		<div className={styles.container}>
			<div className={styles.controls}>

				<input
					type="range"
					min="50"
					max="200"
					value={size}
					onChange={(e) => setSize(Number(e.target.value))}
					className={styles.slider}
				/>
				<Button type="primary" shape="circle" onClick={() => setRotation(rotation - 90)} icon={<RotateLeftOutlined />} size="middle" />
				<Button type="primary" shape="circle" onClick={() => setRotation(rotation + 90)} icon={<RotateRightOutlined />} size="middle" />
				<Button type="primary" shape="circle" onClick={handleDownload} icon={<DownloadOutlined />} size="middle" />


			</div>

			<div className={styles.imageContainer}>
				<div
					className={styles.imageWrapper}
					style={{
						transform: `rotate(${rotation}deg)`
					}}
				>
					<img
						src={imageUrl}
						alt="Preview"
						className={styles.image}
						style={{
							width: `${size}%`
						}}
					/>
				</div>
			</div>
		</div>
	);
};
