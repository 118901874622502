import Authenticated from '@authentication/authenticated';
import CallbackPage from '@authentication/CallbackPage';
import Login from '@authentication/Login';
import { LogoutPage } from '@authentication/LogoutPage';
import { AccountsManagement } from '@components/accountsManagement/AccountsManagement';
import { CreatedDocuments } from '@components/createdDocuments/CreatedDocuments';
import { Departments } from '@components/departments/Departments';
import { ImportedDocuments } from '@components/importedDocuments/ImportedDocuments';
import { NHFSettlements } from '@components/nhfSettlements/NHFSettlements';
import { NHFSettlementsOrders } from '@components/nhfSettlements/orders/NHFSettlementsOrders';
import { OrderGroups } from '@components/orderGroups/OrderGroups';
import { OrderLines } from '@components/orderLines/OrderLines';
import { Orders } from '@components/orders/Orders';
import { Pharmacies } from '@components/pharmacies/Pharmacies';
import { PharmacyEdit } from '@components/pharmacies/pharmacyEdit/PharmacyEdit';
import { PharmacyView } from '@components/pharmacies/pharmacyView/PharmacyView';
import { PharmaciesNoteGroups } from '@components/pharmaciesNoteGroups/PharmaciesNoteGroups';
import { CorrectionNote } from '@components/pharmacyNote/correctionNote/CorrectionNote';
import { LossNote } from '@components/pharmacyNote/lossNote/LossNote';
import { OverpayNote } from '@components/pharmacyNote/overpayNote/OverpayNote';
import { PharmacyNote } from '@components/pharmacyNote/PharmacyNote';
import { PharmacyNotes } from '@components/pharmacyNotes/PharmacyNotes';
import { PriceLists } from '@components/priceLists/PriceLists';
import { Reports } from '@components/reports/Reports';
import { SendingPayments } from '@components/sendingPayments/SendingPayments';
import { useRootData } from '@hooks/hook';
import { useCheckSessionHook } from '@hooks/useCheckSessionHook';
import { useIdleHook } from '@hooks/useIdleHook';
import { InternalTaxNoteType } from '@services/src/models';
import { Spin } from 'antd';
import { observer } from 'mobx-react';
import React from 'react';
import {
	Redirect,
	Route,
	RouteComponentProps,
	Switch,
	withRouter,
} from 'react-router-dom';
import { IAccountDetailsStore } from 'stores/AccountDetailsStore';
import { IDictionaryStore } from 'stores/DictionaryStore';
import SecuredPrimaryView from 'views/SecuredPrimaryView';

interface IProps { }

const App: React.FC<RouteComponentProps<any> & IProps> = () => {
	const dictionaryStore: IDictionaryStore = useRootData(
		(store) => store.dictionaryStore
	);

	const accountDetailsStore: IAccountDetailsStore = useRootData(
		(store) => store.accountDetailsStore
	);
	useIdleHook();

	useCheckSessionHook();

	const switchView = () => {
		return <>
			<Spin
				tip="Loading..."
				spinning={
					!dictionaryStore.initialized.get() &&
					accountDetailsStore.oidcUser.get() !== undefined
				}
			>
				{accountDetailsStore.oidcUser.get() && (
					<Switch>
						<Route
							path="/"
							exact={true}
							render={(props) => <Redirect to="/orderGroups" />}
						/>

						<Route
							path="/orderGroups"
							exact={true}
							render={(props) => (
								<SecuredPrimaryView content={<OrderGroups />} />
							)}
						/>
						<Route
							path="/orderGroups/:id"
							exact={true}
							render={(props) => (
								<SecuredPrimaryView
									content={
										<Orders
											groupId={props.match.params.id}
										/>
									}
								/>
							)}
						/>
						<Route
							path="/order/:orderId"
							exact={true}
							render={(props) => (
								<SecuredPrimaryView
									content={
										<OrderLines
											orderId={props.match.params.orderId}
											isInSearch={false}
										/>
									}
								/>
							)}
						/>
						<Route
							exact={true}
							path="/priceLists"
							render={(props) => (
								<SecuredPrimaryView content={<PriceLists />} />
							)}
						/>
						<Route
							exact={true}
							path="/nhfSettlements"
							render={(props) => (
								<SecuredPrimaryView
									content={<NHFSettlements />}
								/>
							)}
						/>
						<Route
							path="/nhfSettlements/:id"
							exact={true}
							render={(props) => (
								<SecuredPrimaryView
									content={
										<NHFSettlementsOrders
											templateId={props.match.params.id}
										/>
									}
								/>
							)}
						/>
						<Route
							exact={true}
							path="/pharmacyPayments"
							render={(props) => (
								<SecuredPrimaryView
									content={<PharmaciesNoteGroups />}
								/>
							)}
						/>
						<Route
							exact={true}
							path="/pharmacyPayments/:groupId/:pharmacyId"
							render={(props) => (
								<SecuredPrimaryView
									content={
										<PharmacyNotes
											groupId={props.match.params.groupId}
											pharmacyId={
												props.match.params.pharmacyId
											}
										/>
									}
								/>
							)}
						/>
						<Route
							exact={true}
							path="/pharmacyNote/create/:id?"
							render={(props) => (
								<SecuredPrimaryView
									content={
										<PharmacyNote
											pharmacyId={
												props.match.params.id ===
													'undefined'
													? undefined
													: props.match.params.id
											}
										/>
									}
								/>
							)}
						/>
						<Route
							exact={true}
							path="/pharmacyNote/view/:id"
							render={(props) => (
								<SecuredPrimaryView
									content={
										<PharmacyNote
											noteId={props.match.params.id}
											editMode={false}
										/>
									}
								/>
							)}
						/>

						<Route
							exact={true}
							path="/pharmacyNote/edit/:id"
							render={(props) => (
								<SecuredPrimaryView
									content={
										<PharmacyNote
											noteId={props.match.params.id}
											editMode={true}
										/>
									}
								/>
							)}
						/>

						<Route
							exact={true}
							path="/correctionNote/create/:groupId"
							render={(props) => (
								<SecuredPrimaryView
									content={
										<CorrectionNote
											editMode={false}
											readOnly={false}
											groupId={props.match.params.groupId}
											internalTaxNoteType={
												InternalTaxNoteType.Correction
											}
										/>
									}
								/>
							)}
						/>
						<Route
							exact={true}
							path="/correctionNote/edit/:groupId/:noteId"
							render={(props) => (
								<SecuredPrimaryView
									content={
										<CorrectionNote
											editMode={true}
											readOnly={false}
											correctionNoteId={
												props.match.params.noteId
											}
											groupId={props.match.params.groupId}
											internalTaxNoteType={
												InternalTaxNoteType.Correction
											}
										/>
									}
								/>
							)}
						/>
						<Route
							exact={true}
							path="/correctionNote/view/:groupId/:noteId"
							render={(props) => (
								<SecuredPrimaryView
									content={
										<CorrectionNote
											editMode={false}
											readOnly={true}
											correctionNoteId={
												props.match.params.noteId
											}
											groupId={props.match.params.groupId}
											internalTaxNoteType={
												InternalTaxNoteType.Correction
											}
										/>
									}
								/>
							)}
						/>

						<Route
							exact={true}
							path="/lossNote/create/:groupId"
							render={(props) => (
								<SecuredPrimaryView
									content={
										<LossNote
											editMode={false}
											readOnly={false}
											groupId={props.match.params.groupId}
											internalTaxNoteType={
												InternalTaxNoteType.Loss
											}
										/>
									}
								/>
							)}
						/>
						<Route
							exact={true}
							path="/lossNote/edit/:groupId/:noteId"
							render={(props) => (
								<SecuredPrimaryView
									content={
										<LossNote
											editMode={true}
											readOnly={false}
											lossNoteId={
												props.match.params.noteId
											}
											groupId={props.match.params.groupId}
											internalTaxNoteType={
												InternalTaxNoteType.Loss
											}
										/>
									}
								/>
							)}
						/>
						<Route
							exact={true}
							path="/lossNote/view/:groupId/:noteId"
							render={(props) => (
								<SecuredPrimaryView
									content={
										<LossNote
											editMode={false}
											readOnly={true}
											lossNoteId={
												props.match.params.noteId
											}
											groupId={props.match.params.groupId}
											internalTaxNoteType={
												InternalTaxNoteType.Loss
											}
										/>
									}
								/>
							)}
						/>

						<Route
							exact={true}
							path="/overpayNote/create/:groupId/:returnNoteId"
							render={(props) => (
								<SecuredPrimaryView
									content={
										<OverpayNote
											editMode={false}
											readOnly={false}
											groupId={props.match.params.groupId}
											returnNoteId={
												props.match.params.returnNoteId
											}
											internalTaxNoteType={
												InternalTaxNoteType.Overpay
											}
										/>
									}
								/>
							)}
						/>
						<Route
							exact={true}
							path="/overpayNote/edit/:groupId/:noteId/:returnNoteId"
							render={(props) => (
								<SecuredPrimaryView
									content={
										<OverpayNote
											editMode={true}
											readOnly={false}
											overpayNoteId={
												props.match.params.noteId
											}
											returnNoteId={
												props.match.params.returnNoteId
											}
											groupId={props.match.params.groupId}
											internalTaxNoteType={
												InternalTaxNoteType.Overpay
											}
										/>
									}
								/>
							)}
						/>
						<Route
							exact={true}
							path="/overpayNote/view/:groupId/:noteId/:returnNoteId"
							render={(props) => (
								<SecuredPrimaryView
									content={
										<OverpayNote
											editMode={false}
											readOnly={true}
											returnNoteId={
												props.match.params.returnNoteId
											}
											overpayNoteId={
												props.match.params.noteId
											}
											groupId={props.match.params.groupId}
											internalTaxNoteType={
												InternalTaxNoteType.Overpay
											}
										/>
									}
								/>
							)}
						/>
						<Route
							path="/pharmacies"
							exact={true}
							render={(props) => (
								<SecuredPrimaryView content={<Pharmacies />} />
							)}
						/>
						<Route
							path="/pharmacies/new"
							exact={true}
							render={(props) => (
								<SecuredPrimaryView
									content={<PharmacyEdit />}
								/>
							)}
						/>
						<Route
							path="/pharmacies/edit/:id"
							exact={true}
							render={(props) => (
								<SecuredPrimaryView
									content={
										<PharmacyEdit
											pharmacyId={props.match.params.id}
										/>
									}
								/>
							)}
						/>
						<Route
							path="/pharmacies/details/:id"
							exact={true}
							render={(props) => (
								<SecuredPrimaryView
									content={
										<PharmacyView
											pharmacyId={props.match.params.id}
										/>
									}
								/>
							)}
						/>
						<Route
							path="/departments"
							render={(props) => (
								<SecuredPrimaryView content={<Departments />} />
							)}
						/>
						<Route
							path="/importedDocuments"
							render={(props) => (
								<SecuredPrimaryView
									content={<ImportedDocuments />}
								/>
							)}
						/>
						<Route
							path="/createdDocuments"
							render={(props) => (
								<SecuredPrimaryView
									content={<CreatedDocuments />}
								/>
							)}
						/>
						<Route
							path="/reports"
							render={(props) => (
								<SecuredPrimaryView content={<Reports />} />
							)}
						/>

						<Route
							path="/sendingPayments"
							render={(props) => (
								<SecuredPrimaryView
									content={<SendingPayments />}
								/>
							)}
						/>

						<Route
							path="/accountsManagement"
							render={(props) => (
								<SecuredPrimaryView
									content={<AccountsManagement />}
								/>
							)}
						/>
						<Route
							path="/logout"
							render={(props) => <LogoutPage />}
						/>
					</Switch>
				)}
			</Spin>

			<Switch>
				<Route
					path="/"
					exact={true}
					render={(props) => <Redirect to="/login" />}
				/>
				<Route
					path="/authCallback"
					render={(props) => <CallbackPage />}
				/>
				<Route
					exact={true}
					path="/login"
					render={(props) => <Login />}
				/>
			</Switch>
		</>
	};
	return (

		<Authenticated switchView={switchView()}>
			{/* <>
				{switchView()}
			</> */}

		</Authenticated>


	);
};

export default withRouter(observer(App));
