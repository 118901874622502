/*
 * Code generated by Microsoft (R) AutoRest Code Generator.
 * Changes may cause incorrect behavior and will be lost if the code is regenerated.
 */

import * as msRest from "@azure/ms-rest-js";


export const DictionaryValueDto: msRest.CompositeMapper = {
  serializedName: "DictionaryValueDto",
  type: {
    name: "Composite",
    className: "DictionaryValueDto",
    modelProperties: {
      id: {
        serializedName: "id",
        type: {
          name: "Number"
        }
      },
      codeName: {
        serializedName: "codeName",
        type: {
          name: "String"
        }
      },
      value: {
        serializedName: "value",
        type: {
          name: "String"
        }
      },
      dictionaryId: {
        serializedName: "dictionaryId",
        type: {
          name: "Number"
        }
      }
    }
  }
};

export const DictionaryDto: msRest.CompositeMapper = {
  serializedName: "DictionaryDto",
  type: {
    name: "Composite",
    className: "DictionaryDto",
    modelProperties: {
      codeName: {
        serializedName: "codeName",
        type: {
          name: "String"
        }
      },
      name: {
        serializedName: "name",
        type: {
          name: "String"
        }
      },
      values: {
        serializedName: "values",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "DictionaryValueDto"
            }
          }
        }
      }
    }
  }
};

export const OrderDto: msRest.CompositeMapper = {
  serializedName: "OrderDto",
  type: {
    name: "Composite",
    className: "OrderDto",
    modelProperties: {
      id: {
        serializedName: "id",
        type: {
          name: "Uuid"
        }
      },
      nhfRealizationId: {
        serializedName: "nhfRealizationId",
        type: {
          name: "String"
        }
      },
      realizationId: {
        serializedName: "realizationId",
        type: {
          name: "Number"
        }
      },
      year: {
        serializedName: "year",
        type: {
          name: "Number"
        }
      },
      month: {
        serializedName: "month",
        type: {
          name: "Number"
        }
      },
      downloadId: {
        serializedName: "downloadId",
        type: {
          name: "Number"
        }
      },
      kodKom: {
        serializedName: "kodKom",
        type: {
          name: "Number"
        }
      },
      documentType: {
        serializedName: "documentType",
        type: {
          name: "String"
        }
      },
      orderNumber: {
        serializedName: "orderNumber",
        type: {
          name: "String"
        }
      },
      orderDate: {
        serializedName: "orderDate",
        type: {
          name: "DateTime"
        }
      },
      realizationMonthsQty: {
        serializedName: "realizationMonthsQty",
        type: {
          name: "Number"
        }
      },
      orderRealizationStartDate: {
        serializedName: "orderRealizationStartDate",
        type: {
          name: "DateTime"
        }
      },
      pesel: {
        serializedName: "pesel",
        type: {
          name: "String"
        }
      },
      value: {
        serializedName: "value",
        type: {
          name: "Number"
        }
      },
      refund: {
        serializedName: "refund",
        type: {
          name: "Number"
        }
      },
      reportDate: {
        serializedName: "reportDate",
        type: {
          name: "DateTime"
        }
      },
      internalNumber: {
        nullable: true,
        serializedName: "internalNumber",
        type: {
          name: "Number"
        }
      },
      pharmacyId: {
        serializedName: "pharmacyId",
        type: {
          name: "Uuid"
        }
      },
      pharmacyName: {
        serializedName: "pharmacyName",
        type: {
          name: "String"
        }
      },
      placeId: {
        nullable: true,
        serializedName: "placeId",
        type: {
          name: "Number"
        }
      },
      orderState: {
        serializedName: "orderState",
        type: {
          name: "Enum",
          allowedValues: [
            "Imported",
            "Reported",
            "ApprovedByNhf",
            "MarkedToCancel",
            "SentToCancel",
            "Canceled",
            "Restored",
            "SettledNhf"
          ]
        }
      },
      taxNoteOrderState: {
        serializedName: "taxNoteOrderState",
        type: {
          name: "Enum",
          allowedValues: [
            "NotAssigned",
            "Assigned",
            "ReadyToSend",
            "SentToSap",
            "Paid",
            "NotPaid"
          ]
        }
      },
      cancellationReasonId: {
        nullable: true,
        serializedName: "cancellationReasonId",
        type: {
          name: "Number"
        }
      },
      cancellationReasonText: {
        serializedName: "cancellationReasonText",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const OrderSearchDto: msRest.CompositeMapper = {
  serializedName: "OrderSearchDto",
  type: {
    name: "Composite",
    className: "OrderSearchDto",
    modelProperties: {
      id: {
        serializedName: "id",
        type: {
          name: "Uuid"
        }
      },
      orderNumber: {
        serializedName: "orderNumber",
        type: {
          name: "String"
        }
      },
      realizationId: {
        serializedName: "realizationId",
        type: {
          name: "Number"
        }
      }
    }
  }
};

export const OrderRealizationDto: msRest.CompositeMapper = {
  serializedName: "OrderRealizationDto",
  type: {
    name: "Composite",
    className: "OrderRealizationDto",
    modelProperties: {
      id: {
        serializedName: "id",
        type: {
          name: "Uuid"
        }
      },
      nhfRealizationId: {
        serializedName: "nhfRealizationId",
        type: {
          name: "String"
        }
      },
      realizationId: {
        serializedName: "realizationId",
        type: {
          name: "Number"
        }
      }
    }
  }
};

export const OrderReferencesDto: msRest.CompositeMapper = {
  serializedName: "OrderReferencesDto",
  type: {
    name: "Composite",
    className: "OrderReferencesDto",
    modelProperties: {
      orderId: {
        serializedName: "orderId",
        type: {
          name: "Uuid"
        }
      },
      referenceType: {
        serializedName: "referenceType",
        type: {
          name: "Enum",
          allowedValues: [
            "NOTE",
            "CORRECTION_NOTE",
            "REPORT_FILE",
            "REPORT_CORRECTION_FILE",
            "RETURN_DOCUMENT"
          ]
        }
      },
      identifierId: {
        serializedName: "identifierId",
        type: {
          name: "Uuid"
        }
      },
      createdDate: {
        serializedName: "createdDate",
        type: {
          name: "DateTime"
        }
      },
      details: {
        serializedName: "details",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const PharmacyNotePredictedToCorrectionDto: msRest.CompositeMapper = {
  serializedName: "PharmacyNotePredictedToCorrectionDto",
  type: {
    name: "Composite",
    className: "PharmacyNotePredictedToCorrectionDto",
    modelProperties: {
      pharmacyTaxNoteId: {
        serializedName: "pharmacyTaxNoteId",
        type: {
          name: "Uuid"
        }
      },
      noteNumber: {
        serializedName: "noteNumber",
        type: {
          name: "String"
        }
      },
      placeId: {
        serializedName: "placeId",
        type: {
          name: "Number"
        }
      },
      year: {
        serializedName: "year",
        type: {
          name: "Number"
        }
      },
      month: {
        serializedName: "month",
        type: {
          name: "Number"
        }
      },
      predictedCorrectionNoteValue: {
        serializedName: "predictedCorrectionNoteValue",
        type: {
          name: "Number"
        }
      },
      predictedCorrectionInternalNumber: {
        serializedName: "predictedCorrectionInternalNumber",
        type: {
          name: "String"
        }
      },
      pharmacyName: {
        serializedName: "pharmacyName",
        type: {
          name: "String"
        }
      },
      pharmacyId: {
        serializedName: "pharmacyId",
        type: {
          name: "Uuid"
        }
      },
      note: {
        serializedName: "note",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const PharmacyTaxNoteGroupManualOperationDto: msRest.CompositeMapper = {
  serializedName: "PharmacyTaxNoteGroupManualOperationDto",
  type: {
    name: "Composite",
    className: "PharmacyTaxNoteGroupManualOperationDto",
    modelProperties: {
      id: {
        serializedName: "id",
        type: {
          name: "Uuid"
        }
      },
      manualOperationType: {
        serializedName: "manualOperationType",
        type: {
          name: "Enum",
          allowedValues: [
            "Return",
            "Payment"
          ]
        }
      },
      value: {
        serializedName: "value",
        type: {
          name: "Number"
        }
      },
      date: {
        serializedName: "date",
        type: {
          name: "DateTime"
        }
      }
    }
  }
};

export const PharmacyTaxNoteGroupReturnDocumentDto: msRest.CompositeMapper = {
  serializedName: "PharmacyTaxNoteGroupReturnDocumentDto",
  type: {
    name: "Composite",
    className: "PharmacyTaxNoteGroupReturnDocumentDto",
    modelProperties: {
      id: {
        serializedName: "id",
        type: {
          name: "Uuid"
        }
      },
      pharmacyTaxNoteGroupId: {
        serializedName: "pharmacyTaxNoteGroupId",
        type: {
          name: "Uuid"
        }
      },
      documentNumber: {
        serializedName: "documentNumber",
        type: {
          name: "Number"
        }
      },
      documentName: {
        serializedName: "documentName",
        type: {
          name: "String"
        }
      },
      documentId: {
        serializedName: "documentId",
        type: {
          name: "Uuid"
        }
      }
    }
  }
};

export const PharmacyTaxNoteDto: msRest.CompositeMapper = {
  serializedName: "PharmacyTaxNoteDto",
  type: {
    name: "Composite",
    className: "PharmacyTaxNoteDto",
    modelProperties: {
      id: {
        serializedName: "id",
        type: {
          name: "Uuid"
        }
      },
      mainPharmacyTaxNoteId: {
        serializedName: "mainPharmacyTaxNoteId",
        type: {
          name: "Uuid"
        }
      },
      noteDate: {
        serializedName: "noteDate",
        type: {
          name: "DateTime"
        }
      },
      noteNumber: {
        serializedName: "noteNumber",
        type: {
          name: "String"
        }
      },
      noteValue: {
        serializedName: "noteValue",
        type: {
          name: "Number"
        }
      },
      lossValue: {
        serializedName: "lossValue",
        type: {
          name: "Number"
        }
      },
      returnValue: {
        serializedName: "returnValue",
        type: {
          name: "Number"
        }
      },
      overpayValue: {
        serializedName: "overpayValue",
        type: {
          name: "Number"
        }
      },
      ordersValue: {
        serializedName: "ordersValue",
        type: {
          name: "Number"
        }
      },
      settledOrdersValue: {
        serializedName: "settledOrdersValue",
        type: {
          name: "Number"
        }
      },
      taxNoteType: {
        serializedName: "taxNoteType",
        type: {
          name: "Enum",
          allowedValues: [
            "Main",
            "Correction",
            "Loss"
          ]
        }
      },
      internalTaxNoteType: {
        serializedName: "internalTaxNoteType",
        type: {
          name: "Enum",
          allowedValues: [
            "Main",
            "Correction",
            "Debit",
            "Loss",
            "Return",
            "PayLoss",
            "Overpay"
          ]
        }
      },
      taxNoteSource: {
        serializedName: "taxNoteSource",
        type: {
          name: "Enum",
          allowedValues: [
            "Phoenix",
            "Portal"
          ]
        }
      },
      paymentState: {
        serializedName: "paymentState",
        type: {
          name: "Enum",
          allowedValues: [
            "Created",
            "ReadyToSend",
            "SentToSap",
            "Paid",
            "Compensated"
          ]
        }
      },
      hasCorrections: {
        serializedName: "hasCorrections",
        type: {
          name: "Boolean"
        }
      },
      internalNumber: {
        serializedName: "internalNumber",
        type: {
          name: "String"
        }
      },
      year: {
        serializedName: "year",
        type: {
          name: "Number"
        }
      },
      month: {
        serializedName: "month",
        type: {
          name: "Number"
        }
      },
      returnedValue: {
        serializedName: "returnedValue",
        type: {
          name: "Number"
        }
      },
      paidValue: {
        serializedName: "paidValue",
        type: {
          name: "Number"
        }
      },
      createDate: {
        serializedName: "createDate",
        type: {
          name: "DateTime"
        }
      },
      hasOverpay: {
        serializedName: "hasOverpay",
        type: {
          name: "Boolean"
        }
      },
      isOverpayDisabled: {
        serializedName: "isOverpayDisabled",
        type: {
          name: "Boolean"
        }
      },
      isOverpay: {
        readOnly: true,
        serializedName: "isOverpay",
        type: {
          name: "Boolean"
        }
      },
      confirmedBy: {
        serializedName: "confirmedBy",
        type: {
          name: "String"
        }
      },
      noteValueSummary: {
        nullable: true,
        serializedName: "noteValueSummary",
        type: {
          name: "Number"
        }
      },
      overpayValueSummary: {
        nullable: true,
        serializedName: "overpayValueSummary",
        type: {
          name: "Number"
        }
      },
      ordersValueSummary: {
        nullable: true,
        serializedName: "ordersValueSummary",
        type: {
          name: "Number"
        }
      },
      settledOrdersValueSummary: {
        nullable: true,
        serializedName: "settledOrdersValueSummary",
        type: {
          name: "Number"
        }
      },
      lossValueSummary: {
        nullable: true,
        serializedName: "lossValueSummary",
        type: {
          name: "Number"
        }
      },
      returnedValueSummary: {
        nullable: true,
        serializedName: "returnedValueSummary",
        type: {
          name: "Number"
        }
      },
      paidValueSummary: {
        nullable: true,
        serializedName: "paidValueSummary",
        type: {
          name: "Number"
        }
      }
    }
  }
};

export const PharmacyTaxNotePaymentWithNotesDto: msRest.CompositeMapper = {
  serializedName: "PharmacyTaxNotePaymentWithNotesDto",
  type: {
    name: "Composite",
    className: "PharmacyTaxNotePaymentWithNotesDto",
    modelProperties: {
      id: {
        serializedName: "id",
        type: {
          name: "Uuid"
        }
      },
      paymentId: {
        serializedName: "paymentId",
        type: {
          name: "Uuid"
        }
      },
      paymentName: {
        serializedName: "paymentName",
        type: {
          name: "String"
        }
      },
      noteValueSum: {
        serializedName: "noteValueSum",
        type: {
          name: "Number"
        }
      },
      ordersValueSum: {
        serializedName: "ordersValueSum",
        type: {
          name: "Number"
        }
      },
      settledOrdersValueSum: {
        serializedName: "settledOrdersValueSum",
        type: {
          name: "Number"
        }
      },
      paymentState: {
        serializedName: "paymentState",
        type: {
          name: "Enum",
          allowedValues: [
            "Created",
            "ReadyToSend",
            "SentToSap",
            "Paid",
            "Compensated"
          ]
        }
      },
      confirmationState: {
        serializedName: "confirmationState",
        type: {
          name: "Enum",
          allowedValues: [
            "CONFIRMED",
            "REFUSED",
            "RECOVERED"
          ]
        }
      },
      refuseDate: {
        serializedName: "refuseDate",
        type: {
          name: "DateTime"
        }
      },
      recoverDate: {
        serializedName: "recoverDate",
        type: {
          name: "DateTime"
        }
      },
      pharmacyTaxNotes: {
        serializedName: "pharmacyTaxNotes",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "PharmacyTaxNoteDto"
            }
          }
        }
      }
    }
  }
};

export const PharmacyTaxNotePaymentConfirmationDto: msRest.CompositeMapper = {
  serializedName: "PharmacyTaxNotePaymentConfirmationDto",
  type: {
    name: "Composite",
    className: "PharmacyTaxNotePaymentConfirmationDto",
    modelProperties: {
      id: {
        serializedName: "id",
        type: {
          name: "Uuid"
        }
      },
      pharmacyTaxNoteId: {
        serializedName: "pharmacyTaxNoteId",
        type: {
          name: "Uuid"
        }
      },
      amount: {
        serializedName: "amount",
        type: {
          name: "Number"
        }
      },
      confirmationDate: {
        serializedName: "confirmationDate",
        type: {
          name: "DateTime"
        }
      }
    }
  }
};

export const ProductPriceSearchDto: msRest.CompositeMapper = {
  serializedName: "ProductPriceSearchDto",
  type: {
    name: "Composite",
    className: "ProductPriceSearchDto",
    modelProperties: {
      id: {
        serializedName: "id",
        type: {
          name: "Uuid"
        }
      },
      productName: {
        serializedName: "productName",
        type: {
          name: "String"
        }
      },
      productId: {
        serializedName: "productId",
        type: {
          name: "Number"
        }
      }
    }
  }
};

export const PriceListDto: msRest.CompositeMapper = {
  serializedName: "PriceListDto",
  type: {
    name: "Composite",
    className: "PriceListDto",
    modelProperties: {
      id: {
        serializedName: "id",
        type: {
          name: "Uuid"
        }
      },
      priceListId: {
        serializedName: "priceListId",
        type: {
          name: "Number"
        }
      },
      name: {
        serializedName: "name",
        type: {
          name: "String"
        }
      },
      voivodeshipId: {
        serializedName: "voivodeshipId",
        type: {
          name: "String"
        }
      },
      validFrom: {
        serializedName: "validFrom",
        type: {
          name: "DateTime"
        }
      },
      validTo: {
        serializedName: "validTo",
        type: {
          name: "DateTime"
        }
      },
      priceListGroup: {
        serializedName: "priceListGroup",
        type: {
          name: "Enum",
          allowedValues: [
            "Auxiliaries",
            "OrthopedicProducts"
          ]
        }
      }
    }
  }
};

export const VoivodeshipContractDto: msRest.CompositeMapper = {
  serializedName: "VoivodeshipContractDto",
  type: {
    name: "Composite",
    className: "VoivodeshipContractDto",
    modelProperties: {
      id: {
        serializedName: "id",
        type: {
          name: "Uuid"
        }
      },
      contractStartDate: {
        serializedName: "contractStartDate",
        type: {
          name: "DateTime"
        }
      },
      contractEndDate: {
        serializedName: "contractEndDate",
        type: {
          name: "DateTime"
        }
      },
      mainContractNumber: {
        serializedName: "mainContractNumber",
        type: {
          name: "String"
        }
      },
      contractNumber: {
        serializedName: "contractNumber",
        type: {
          name: "String"
        }
      },
      mainContractStartDate: {
        serializedName: "mainContractStartDate",
        type: {
          name: "DateTime"
        }
      },
      mainContractEndDate: {
        serializedName: "mainContractEndDate",
        type: {
          name: "DateTime"
        }
      }
    }
  }
};

export const VoivodeshipIdentifierDto: msRest.CompositeMapper = {
  serializedName: "VoivodeshipIdentifierDto",
  type: {
    name: "Composite",
    className: "VoivodeshipIdentifierDto",
    modelProperties: {
      id: {
        serializedName: "id",
        type: {
          name: "Uuid"
        }
      },
      identifier: {
        serializedName: "identifier",
        type: {
          name: "Number"
        }
      },
      identifierStartDate: {
        serializedName: "identifierStartDate",
        type: {
          name: "DateTime"
        }
      },
      identifierEndDate: {
        serializedName: "identifierEndDate",
        type: {
          name: "DateTime"
        }
      }
    }
  }
};

export const VoivodeshipDetailsDto: msRest.CompositeMapper = {
  serializedName: "VoivodeshipDetailsDto",
  type: {
    name: "Composite",
    className: "VoivodeshipDetailsDto",
    modelProperties: {
      id: {
        serializedName: "id",
        type: {
          name: "Uuid"
        }
      },
      sapId: {
        serializedName: "sapId",
        type: {
          name: "Number"
        }
      },
      name: {
        serializedName: "name",
        type: {
          name: "String"
        }
      },
      shortName: {
        serializedName: "shortName",
        type: {
          name: "String"
        }
      },
      city: {
        serializedName: "city",
        type: {
          name: "String"
        }
      },
      street: {
        serializedName: "street",
        type: {
          name: "String"
        }
      },
      postalCode: {
        serializedName: "postalCode",
        type: {
          name: "String"
        }
      },
      voivodeshipId: {
        serializedName: "voivodeshipId",
        type: {
          name: "Number"
        }
      },
      voivodeshipName: {
        serializedName: "voivodeshipName",
        type: {
          name: "String"
        }
      },
      taxNumber: {
        serializedName: "taxNumber",
        type: {
          name: "String"
        }
      },
      phone: {
        serializedName: "phone",
        type: {
          name: "String"
        }
      },
      phone2: {
        serializedName: "phone2",
        type: {
          name: "String"
        }
      },
      departmentId: {
        serializedName: "departmentId",
        type: {
          name: "String"
        }
      },
      departmentNumber: {
        serializedName: "departmentNumber",
        type: {
          name: "String"
        }
      },
      portalSzoi: {
        serializedName: "portalSzoi",
        type: {
          name: "Enum",
          allowedValues: [
            "Portal",
            "SZOI"
          ]
        }
      },
      installationId: {
        serializedName: "installationId",
        type: {
          name: "Uuid"
        }
      },
      serviceProviderCode: {
        serializedName: "serviceProviderCode",
        type: {
          name: "String"
        }
      },
      user: {
        serializedName: "user",
        type: {
          name: "String"
        }
      },
      password: {
        serializedName: "password",
        type: {
          name: "String"
        }
      },
      currentContractId: {
        readOnly: true,
        serializedName: "currentContractId",
        type: {
          name: "Uuid"
        }
      },
      currentContractNumber: {
        readOnly: true,
        serializedName: "currentContractNumber",
        type: {
          name: "String"
        }
      },
      currentMainContractNumber: {
        readOnly: true,
        serializedName: "currentMainContractNumber",
        type: {
          name: "String"
        }
      },
      currentContractStartDate: {
        readOnly: true,
        serializedName: "currentContractStartDate",
        type: {
          name: "DateTime"
        }
      },
      currentContractEndDate: {
        readOnly: true,
        serializedName: "currentContractEndDate",
        type: {
          name: "DateTime"
        }
      },
      currentMainContractStartDate: {
        readOnly: true,
        serializedName: "currentMainContractStartDate",
        type: {
          name: "DateTime"
        }
      },
      currentMainContractEndDate: {
        readOnly: true,
        serializedName: "currentMainContractEndDate",
        type: {
          name: "DateTime"
        }
      },
      voivodeshipContracts: {
        serializedName: "voivodeshipContracts",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "VoivodeshipContractDto"
            }
          }
        }
      },
      voivodeshipIdentifiers: {
        serializedName: "voivodeshipIdentifiers",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "VoivodeshipIdentifierDto"
            }
          }
        }
      }
    }
  }
};

export const VoivodeshipContactDto: msRest.CompositeMapper = {
  serializedName: "VoivodeshipContactDto",
  type: {
    name: "Composite",
    className: "VoivodeshipContactDto",
    modelProperties: {
      id: {
        serializedName: "id",
        type: {
          name: "Uuid"
        }
      },
      phone: {
        serializedName: "phone",
        type: {
          name: "String"
        }
      },
      email: {
        serializedName: "email",
        type: {
          name: "String"
        }
      },
      description: {
        serializedName: "description",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const AccountSettingsDto: msRest.CompositeMapper = {
  serializedName: "AccountSettingsDto",
  type: {
    name: "Composite",
    className: "AccountSettingsDto",
    modelProperties: {
      voivodeshipFilterDictionaryIds: {
        serializedName: "voivodeshipFilterDictionaryIds",
        type: {
          name: "String"
        }
      },
      settlementPeriodFilterDictionaryValueCodeName: {
        serializedName: "settlementPeriodFilterDictionaryValueCodeName",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const AccountRoleDto: msRest.CompositeMapper = {
  serializedName: "AccountRoleDto",
  type: {
    name: "Composite",
    className: "AccountRoleDto",
    modelProperties: {
      id: {
        serializedName: "id",
        type: {
          name: "Uuid"
        }
      },
      name: {
        serializedName: "name",
        type: {
          name: "String"
        }
      },
      isSuperAdmin: {
        serializedName: "isSuperAdmin",
        type: {
          name: "Boolean"
        }
      },
      pharmacyId: {
        serializedName: "pharmacyId",
        type: {
          name: "Uuid"
        }
      }
    }
  }
};

export const AddressDto: msRest.CompositeMapper = {
  serializedName: "AddressDto",
  type: {
    name: "Composite",
    className: "AddressDto",
    modelProperties: {
      city: {
        serializedName: "city",
        type: {
          name: "String"
        }
      },
      street: {
        serializedName: "street",
        type: {
          name: "String"
        }
      },
      postalCode: {
        serializedName: "postalCode",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const AccountDto: msRest.CompositeMapper = {
  serializedName: "AccountDto",
  type: {
    name: "Composite",
    className: "AccountDto",
    modelProperties: {
      id: {
        serializedName: "id",
        type: {
          name: "Uuid"
        }
      },
      displayName: {
        serializedName: "displayName",
        type: {
          name: "String"
        }
      },
      userPrincipalName: {
        serializedName: "userPrincipalName",
        type: {
          name: "String"
        }
      },
      mail: {
        serializedName: "mail",
        type: {
          name: "String"
        }
      },
      settings: {
        serializedName: "settings",
        type: {
          name: "Composite",
          className: "AccountSettingsDto"
        }
      },
      roles: {
        serializedName: "roles",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "AccountRoleDto"
            }
          }
        }
      },
      address: {
        serializedName: "address",
        type: {
          name: "Composite",
          className: "AddressDto"
        }
      }
    }
  }
};

export const AccountDtoArrayAzureListDto: msRest.CompositeMapper = {
  serializedName: "AccountDtoArrayAzureListDto",
  type: {
    name: "Composite",
    className: "AccountDtoArrayAzureListDto",
    modelProperties: {
      items: {
        serializedName: "items",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "AccountDto"
            }
          }
        }
      },
      skipToken: {
        serializedName: "skipToken",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const AddVoivodeshipContactCommand: msRest.CompositeMapper = {
  serializedName: "AddVoivodeshipContactCommand",
  type: {
    name: "Composite",
    className: "AddVoivodeshipContactCommand",
    modelProperties: {
      voivodeshipId: {
        serializedName: "voivodeshipId",
        type: {
          name: "Uuid"
        }
      },
      phone: {
        serializedName: "phone",
        type: {
          name: "String"
        }
      },
      email: {
        serializedName: "email",
        type: {
          name: "String"
        }
      },
      description: {
        serializedName: "description",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const AssignBalancedPaymentConfirmationCommand: msRest.CompositeMapper = {
  serializedName: "AssignBalancedPaymentConfirmationCommand",
  type: {
    name: "Composite",
    className: "AssignBalancedPaymentConfirmationCommand",
    modelProperties: {
      pharmacyTaxNoteId: {
        serializedName: "pharmacyTaxNoteId",
        type: {
          name: "Uuid"
        }
      }
    }
  }
};

export const AssignPaymentConfirmationCommand: msRest.CompositeMapper = {
  serializedName: "AssignPaymentConfirmationCommand",
  type: {
    name: "Composite",
    className: "AssignPaymentConfirmationCommand",
    modelProperties: {
      pharmacyTaxNoteId: {
        serializedName: "pharmacyTaxNoteId",
        type: {
          name: "Uuid"
        }
      },
      amount: {
        serializedName: "amount",
        type: {
          name: "Number"
        }
      },
      confirmationDate: {
        serializedName: "confirmationDate",
        type: {
          name: "DateTime"
        }
      }
    }
  }
};

export const ChangeStatePharmacyTaxNoteGroupCommand: msRest.CompositeMapper = {
  serializedName: "ChangeStatePharmacyTaxNoteGroupCommand",
  type: {
    name: "Composite",
    className: "ChangeStatePharmacyTaxNoteGroupCommand",
    modelProperties: {
      pharmacyTaxNoteGroupIds: {
        serializedName: "pharmacyTaxNoteGroupIds",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Uuid"
            }
          }
        }
      },
      paymentState: {
        serializedName: "paymentState",
        type: {
          name: "Enum",
          allowedValues: [
            "Created",
            "ReadyToSend",
            "SentToSap",
            "Paid",
            "Compensated"
          ]
        }
      }
    }
  }
};

export const ChangeStatePharmacyTaxNotePaymentCommand: msRest.CompositeMapper = {
  serializedName: "ChangeStatePharmacyTaxNotePaymentCommand",
  type: {
    name: "Composite",
    className: "ChangeStatePharmacyTaxNotePaymentCommand",
    modelProperties: {
      paymentId: {
        serializedName: "paymentId",
        type: {
          name: "Uuid"
        }
      },
      paymentState: {
        serializedName: "paymentState",
        type: {
          name: "Enum",
          allowedValues: [
            "Created",
            "ReadyToSend",
            "SentToSap",
            "Paid",
            "Compensated"
          ]
        }
      }
    }
  }
};

export const CreateCorrectionPharmacyTaxNoteCommand: msRest.CompositeMapper = {
  serializedName: "CreateCorrectionPharmacyTaxNoteCommand",
  type: {
    name: "Composite",
    className: "CreateCorrectionPharmacyTaxNoteCommand",
    modelProperties: {
      pharmacyTaxNoteId: {
        serializedName: "pharmacyTaxNoteId",
        type: {
          name: "Uuid"
        }
      },
      noteDate: {
        serializedName: "noteDate",
        type: {
          name: "DateTime"
        }
      },
      note: {
        serializedName: "note",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const CreateDebitCorrectionPharmacyTaxNoteCommand: msRest.CompositeMapper = {
  serializedName: "CreateDebitCorrectionPharmacyTaxNoteCommand",
  type: {
    name: "Composite",
    className: "CreateDebitCorrectionPharmacyTaxNoteCommand",
    modelProperties: {
      pharmacyId: {
        serializedName: "pharmacyId",
        type: {
          name: "Uuid"
        }
      },
      year: {
        serializedName: "year",
        type: {
          name: "Number"
        }
      },
      month: {
        serializedName: "month",
        type: {
          name: "Number"
        }
      },
      noteDate: {
        serializedName: "noteDate",
        type: {
          name: "DateTime"
        }
      },
      note: {
        serializedName: "note",
        type: {
          name: "String"
        }
      },
      pharmacyTaxNoteOrders: {
        serializedName: "pharmacyTaxNoteOrders",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Uuid"
            }
          }
        }
      }
    }
  }
};

export const CreateLossPharmacyTaxNoteCommand: msRest.CompositeMapper = {
  serializedName: "CreateLossPharmacyTaxNoteCommand",
  type: {
    name: "Composite",
    className: "CreateLossPharmacyTaxNoteCommand",
    modelProperties: {
      pharmacyTaxNoteId: {
        serializedName: "pharmacyTaxNoteId",
        type: {
          name: "Uuid"
        }
      },
      noteDate: {
        serializedName: "noteDate",
        type: {
          name: "DateTime"
        }
      },
      noteValue: {
        serializedName: "noteValue",
        type: {
          name: "Number"
        }
      },
      note: {
        serializedName: "note",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const CreateNoteResponseDto: msRest.CompositeMapper = {
  serializedName: "CreateNoteResponseDto",
  type: {
    name: "Composite",
    className: "CreateNoteResponseDto",
    modelProperties: {
      pharmacyTaxNoteId: {
        serializedName: "pharmacyTaxNoteId",
        type: {
          name: "Uuid"
        }
      },
      pharmacyTaxNoteGroupId: {
        serializedName: "pharmacyTaxNoteGroupId",
        type: {
          name: "Uuid"
        }
      },
      pharmacyId: {
        serializedName: "pharmacyId",
        type: {
          name: "Uuid"
        }
      },
      pharmacyTaxNoteIdInternalNumber: {
        serializedName: "pharmacyTaxNoteIdInternalNumber",
        type: {
          name: "String"
        }
      },
      canBeConfirmedBy: {
        serializedName: "canBeConfirmedBy",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const CreatePayLossPharmacyTaxNoteCommand: msRest.CompositeMapper = {
  serializedName: "CreatePayLossPharmacyTaxNoteCommand",
  type: {
    name: "Composite",
    className: "CreatePayLossPharmacyTaxNoteCommand",
    modelProperties: {
      year: {
        serializedName: "year",
        type: {
          name: "Number"
        }
      },
      month: {
        serializedName: "month",
        type: {
          name: "Number"
        }
      },
      noteDate: {
        serializedName: "noteDate",
        type: {
          name: "DateTime"
        }
      },
      noteValue: {
        serializedName: "noteValue",
        type: {
          name: "Number"
        }
      },
      note: {
        serializedName: "note",
        type: {
          name: "String"
        }
      },
      pharmacyId: {
        serializedName: "pharmacyId",
        type: {
          name: "Uuid"
        }
      }
    }
  }
};

export const CreatePharmacyCommand: msRest.CompositeMapper = {
  serializedName: "CreatePharmacyCommand",
  type: {
    name: "Composite",
    className: "CreatePharmacyCommand",
    modelProperties: {
      name: {
        serializedName: "name",
        type: {
          name: "String"
        }
      },
      companyName: {
        serializedName: "companyName",
        type: {
          name: "String"
        }
      },
      city: {
        serializedName: "city",
        type: {
          name: "String"
        }
      },
      street: {
        serializedName: "street",
        type: {
          name: "String"
        }
      },
      voivodeshipId: {
        serializedName: "voivodeshipId",
        type: {
          name: "Number"
        }
      },
      taxNumber: {
        serializedName: "taxNumber",
        type: {
          name: "String"
        }
      },
      placeId: {
        nullable: true,
        serializedName: "placeId",
        type: {
          name: "Number"
        }
      },
      endDateOfEssityContract: {
        serializedName: "endDateOfEssityContract",
        type: {
          name: "DateTime"
        }
      },
      dateOfJoiningEssityNet: {
        serializedName: "dateOfJoiningEssityNet",
        type: {
          name: "DateTime"
        }
      },
      type: {
        serializedName: "type",
        type: {
          name: "Enum",
          allowedValues: [
            "PHARMACY",
            "MEDICAL_SHOP"
          ]
        }
      },
      endContractReasonId: {
        nullable: true,
        serializedName: "endContractReasonId",
        type: {
          name: "Number"
        }
      },
      nhfContractId: {
        nullable: true,
        serializedName: "nhfContractId",
        type: {
          name: "Number"
        }
      },
      essityPaperContractNumber: {
        serializedName: "essityPaperContractNumber",
        type: {
          name: "String"
        }
      },
      healthFundID: {
        serializedName: "healthFundID",
        type: {
          name: "String"
        }
      },
      farmapromPharmacyId: {
        nullable: true,
        serializedName: "farmapromPharmacyId",
        type: {
          name: "Number"
        }
      },
      kodKom: {
        nullable: true,
        serializedName: "kodKom",
        type: {
          name: "Number"
        }
      },
      login: {
        serializedName: "login",
        type: {
          name: "String"
        }
      },
      kam: {
        serializedName: "kam",
        type: {
          name: "String"
        }
      },
      mail: {
        serializedName: "mail",
        type: {
          name: "String"
        }
      },
      phoneNumber: {
        serializedName: "phoneNumber",
        type: {
          name: "String"
        }
      },
      salesRepresentative: {
        serializedName: "salesRepresentative",
        type: {
          name: "String"
        }
      },
      postalCode: {
        serializedName: "postalCode",
        type: {
          name: "String"
        }
      },
      district: {
        nullable: true,
        serializedName: "district",
        type: {
          name: "Number"
        }
      },
      pharmacyChain: {
        serializedName: "pharmacyChain",
        type: {
          name: "String"
        }
      },
      description: {
        serializedName: "description",
        type: {
          name: "String"
        }
      },
      iban: {
        serializedName: "iban",
        type: {
          name: "String"
        }
      },
      identifier: {
        nullable: true,
        serializedName: "identifier",
        type: {
          name: "Number"
        }
      },
      businessContinuity: {
        serializedName: "businessContinuity",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const FileData: msRest.CompositeMapper = {
  serializedName: "FileData",
  type: {
    name: "Composite",
    className: "FileData",
    modelProperties: {
      id: {
        serializedName: "id",
        type: {
          name: "Uuid"
        }
      },
      fileName: {
        serializedName: "fileName",
        type: {
          name: "String"
        }
      },
      fileExtension: {
        serializedName: "fileExtension",
        type: {
          name: "String"
        }
      },
      mimeType: {
        serializedName: "mimeType",
        type: {
          name: "String"
        }
      },
      data: {
        serializedName: "data",
        type: {
          name: "ByteArray"
        }
      }
    }
  }
};

export const CreatePharmacyTaxNoteCommand: msRest.CompositeMapper = {
  serializedName: "CreatePharmacyTaxNoteCommand",
  type: {
    name: "Composite",
    className: "CreatePharmacyTaxNoteCommand",
    modelProperties: {
      year: {
        serializedName: "year",
        type: {
          name: "Number"
        }
      },
      month: {
        serializedName: "month",
        type: {
          name: "Number"
        }
      },
      noteDate: {
        serializedName: "noteDate",
        type: {
          name: "DateTime"
        }
      },
      noteValue: {
        serializedName: "noteValue",
        type: {
          name: "Number"
        }
      },
      note: {
        serializedName: "note",
        type: {
          name: "String"
        }
      },
      pharmacyId: {
        serializedName: "pharmacyId",
        type: {
          name: "Uuid"
        }
      },
      pharmacyTaxNoteOrders: {
        serializedName: "pharmacyTaxNoteOrders",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Uuid"
            }
          }
        }
      },
      noteNumber: {
        serializedName: "noteNumber",
        type: {
          name: "String"
        }
      },
      documents: {
        serializedName: "documents",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "FileData"
            }
          }
        }
      },
      isDocumentationComplete: {
        serializedName: "isDocumentationComplete",
        type: {
          name: "Boolean"
        }
      }
    }
  }
};

export const CreatePharmacyTaxNoteCommandForPharmacy: msRest.CompositeMapper = {
  serializedName: "CreatePharmacyTaxNoteCommandForPharmacy",
  type: {
    name: "Composite",
    className: "CreatePharmacyTaxNoteCommandForPharmacy",
    modelProperties: {
      year: {
        serializedName: "year",
        type: {
          name: "Number"
        }
      },
      month: {
        serializedName: "month",
        type: {
          name: "Number"
        }
      },
      noteDate: {
        serializedName: "noteDate",
        type: {
          name: "DateTime"
        }
      },
      noteValue: {
        serializedName: "noteValue",
        type: {
          name: "Number"
        }
      },
      note: {
        serializedName: "note",
        type: {
          name: "String"
        }
      },
      noteNumber: {
        serializedName: "noteNumber",
        type: {
          name: "String"
        }
      },
      fileName: {
        serializedName: "fileName",
        type: {
          name: "String"
        }
      },
      fileExtension: {
        serializedName: "fileExtension",
        type: {
          name: "String"
        }
      },
      mimeType: {
        serializedName: "mimeType",
        type: {
          name: "String"
        }
      },
      data: {
        serializedName: "data",
        type: {
          name: "ByteArray"
        }
      }
    }
  }
};

export const CreatePharmacyTaxNoteGroupManualOperationCommand: msRest.CompositeMapper = {
  serializedName: "CreatePharmacyTaxNoteGroupManualOperationCommand",
  type: {
    name: "Composite",
    className: "CreatePharmacyTaxNoteGroupManualOperationCommand",
    modelProperties: {
      pharmacyTaxNoteGroupId: {
        serializedName: "pharmacyTaxNoteGroupId",
        type: {
          name: "Uuid"
        }
      },
      manualOperationType: {
        serializedName: "manualOperationType",
        type: {
          name: "Enum",
          allowedValues: [
            "Return",
            "Payment"
          ]
        }
      },
      value: {
        serializedName: "value",
        type: {
          name: "Number"
        }
      },
      date: {
        serializedName: "date",
        type: {
          name: "DateTime"
        }
      }
    }
  }
};

export const CreatePharmacyTaxOverpayNoteCommand: msRest.CompositeMapper = {
  serializedName: "CreatePharmacyTaxOverpayNoteCommand",
  type: {
    name: "Composite",
    className: "CreatePharmacyTaxOverpayNoteCommand",
    modelProperties: {
      pharmacyTaxNoteId: {
        serializedName: "pharmacyTaxNoteId",
        type: {
          name: "Uuid"
        }
      },
      noteDate: {
        serializedName: "noteDate",
        type: {
          name: "DateTime"
        }
      },
      note: {
        serializedName: "note",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const DeletePaymentConfirmationCommand: msRest.CompositeMapper = {
  serializedName: "DeletePaymentConfirmationCommand",
  type: {
    name: "Composite",
    className: "DeletePaymentConfirmationCommand",
    modelProperties: {
      pharmacyTaxNotePaymentConfirmationId: {
        serializedName: "pharmacyTaxNotePaymentConfirmationId",
        type: {
          name: "Uuid"
        }
      }
    }
  }
};

export const DeletePaymentConfirmationsCommand: msRest.CompositeMapper = {
  serializedName: "DeletePaymentConfirmationsCommand",
  type: {
    name: "Composite",
    className: "DeletePaymentConfirmationsCommand",
    modelProperties: {
      pharmacyTaxNoteId: {
        serializedName: "pharmacyTaxNoteId",
        type: {
          name: "Uuid"
        }
      }
    }
  }
};

export const DeletePharmacyTaxNoteCommand: msRest.CompositeMapper = {
  serializedName: "DeletePharmacyTaxNoteCommand",
  type: {
    name: "Composite",
    className: "DeletePharmacyTaxNoteCommand",
    modelProperties: {
      id: {
        serializedName: "id",
        type: {
          name: "Uuid"
        }
      }
    }
  }
};

export const DeletePharmacyTaxNoteCommandForPharmacy: msRest.CompositeMapper = {
  serializedName: "DeletePharmacyTaxNoteCommandForPharmacy",
  type: {
    name: "Composite",
    className: "DeletePharmacyTaxNoteCommandForPharmacy",
    modelProperties: {
      id: {
        serializedName: "id",
        type: {
          name: "Uuid"
        }
      }
    }
  }
};

export const DeletePharmacyTaxNoteGroupManualOperationCommand: msRest.CompositeMapper = {
  serializedName: "DeletePharmacyTaxNoteGroupManualOperationCommand",
  type: {
    name: "Composite",
    className: "DeletePharmacyTaxNoteGroupManualOperationCommand",
    modelProperties: {
      id: {
        serializedName: "id",
        type: {
          name: "Uuid"
        }
      }
    }
  }
};

export const FarmapromPharmacyDto: msRest.CompositeMapper = {
  serializedName: "FarmapromPharmacyDto",
  type: {
    name: "Composite",
    className: "FarmapromPharmacyDto",
    modelProperties: {
      id: {
        serializedName: "id",
        type: {
          name: "Uuid"
        }
      },
      farmapromPharmacyId: {
        serializedName: "farmapromPharmacyId",
        type: {
          name: "Number"
        }
      },
      name: {
        serializedName: "name",
        type: {
          name: "String"
        }
      },
      street: {
        serializedName: "street",
        type: {
          name: "String"
        }
      },
      city: {
        serializedName: "city",
        type: {
          name: "String"
        }
      },
      postalCode: {
        serializedName: "postalCode",
        type: {
          name: "String"
        }
      },
      voivodeshipId: {
        serializedName: "voivodeshipId",
        type: {
          name: "Number"
        }
      },
      district: {
        serializedName: "district",
        type: {
          name: "Number"
        }
      },
      kam: {
        serializedName: "kam",
        type: {
          name: "String"
        }
      },
      salesRepresentative: {
        serializedName: "salesRepresentative",
        type: {
          name: "String"
        }
      },
      pharmacyChain: {
        serializedName: "pharmacyChain",
        type: {
          name: "String"
        }
      },
      importId: {
        serializedName: "importId",
        type: {
          name: "Uuid"
        }
      }
    }
  }
};

export const FarmapromPharmacyDtoPagedResult: msRest.CompositeMapper = {
  serializedName: "FarmapromPharmacyDtoPagedResult",
  type: {
    name: "Composite",
    className: "FarmapromPharmacyDtoPagedResult",
    modelProperties: {
      queryable: {
        serializedName: "queryable",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "FarmapromPharmacyDto"
            }
          }
        }
      },
      currentPage: {
        serializedName: "currentPage",
        type: {
          name: "Number"
        }
      },
      pageCount: {
        serializedName: "pageCount",
        type: {
          name: "Number"
        }
      },
      pageSize: {
        serializedName: "pageSize",
        type: {
          name: "Number"
        }
      },
      rowCount: {
        serializedName: "rowCount",
        type: {
          name: "Number"
        }
      }
    }
  }
};

export const FileResultDto: msRest.CompositeMapper = {
  serializedName: "FileResultDto",
  type: {
    name: "Composite",
    className: "FileResultDto",
    modelProperties: {
      fileName: {
        serializedName: "fileName",
        type: {
          name: "String"
        }
      },
      fileExtension: {
        serializedName: "fileExtension",
        type: {
          name: "String"
        }
      },
      mimeType: {
        serializedName: "mimeType",
        type: {
          name: "String"
        }
      },
      data: {
        serializedName: "data",
        type: {
          name: "ByteArray"
        }
      }
    }
  }
};

export const GuidIIdDto: msRest.CompositeMapper = {
  serializedName: "GuidIIdDto",
  type: {
    name: "Composite",
    className: "GuidIIdDto",
    modelProperties: {
      id: {
        readOnly: true,
        serializedName: "id",
        type: {
          name: "Uuid"
        }
      }
    }
  }
};

export const MissingPharmacyDto: msRest.CompositeMapper = {
  serializedName: "MissingPharmacyDto",
  type: {
    name: "Composite",
    className: "MissingPharmacyDto",
    modelProperties: {
      id: {
        serializedName: "id",
        type: {
          name: "Number"
        }
      },
      name: {
        serializedName: "name",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const ImportFarmapromPharmaciesStatusDto: msRest.CompositeMapper = {
  serializedName: "ImportFarmapromPharmaciesStatusDto",
  type: {
    name: "Composite",
    className: "ImportFarmapromPharmaciesStatusDto",
    modelProperties: {
      missingFarmapromIds: {
        serializedName: "missingFarmapromIds",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "MissingPharmacyDto"
            }
          }
        }
      }
    }
  }
};

export const ImportUmxPharmaciesStatusDto: msRest.CompositeMapper = {
  serializedName: "ImportUmxPharmaciesStatusDto",
  type: {
    name: "Composite",
    className: "ImportUmxPharmaciesStatusDto",
    modelProperties: {
      missingKodKomsInDatabase: {
        serializedName: "missingKodKomsInDatabase",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "MissingPharmacyDto"
            }
          }
        }
      },
      missingKodKomsInFile: {
        serializedName: "missingKodKomsInFile",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "MissingPharmacyDto"
            }
          }
        }
      },
      kodKomsInWrongStates: {
        serializedName: "kodKomsInWrongStates",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "MissingPharmacyDto"
            }
          }
        }
      }
    }
  }
};

export const InvitePharmacyCommand: msRest.CompositeMapper = {
  serializedName: "InvitePharmacyCommand",
  type: {
    name: "Composite",
    className: "InvitePharmacyCommand",
    modelProperties: {
      pharmacyId: {
        serializedName: "pharmacyId",
        type: {
          name: "Uuid"
        }
      }
    }
  }
};

export const MarkToCancelCommand: msRest.CompositeMapper = {
  serializedName: "MarkToCancelCommand",
  type: {
    name: "Composite",
    className: "MarkToCancelCommand",
    modelProperties: {
      orderId: {
        serializedName: "orderId",
        type: {
          name: "Uuid"
        }
      },
      cancellationReasonId: {
        nullable: true,
        serializedName: "cancellationReasonId",
        type: {
          name: "Number"
        }
      },
      cancellationReasonText: {
        serializedName: "cancellationReasonText",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const OrderLineDetailsDto: msRest.CompositeMapper = {
  serializedName: "OrderLineDetailsDto",
  type: {
    name: "Composite",
    className: "OrderLineDetailsDto",
    modelProperties: {
      qty: {
        serializedName: "qty",
        type: {
          name: "Number"
        }
      },
      productId: {
        nullable: true,
        serializedName: "productId",
        type: {
          name: "Number"
        }
      },
      productVersionNFZCode: {
        serializedName: "productVersionNFZCode",
        type: {
          name: "String"
        }
      },
      price: {
        serializedName: "price",
        type: {
          name: "Number"
        }
      },
      value: {
        readOnly: true,
        serializedName: "value",
        type: {
          name: "Number"
        }
      },
      productName: {
        serializedName: "productName",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const OrderErrorDto: msRest.CompositeMapper = {
  serializedName: "OrderErrorDto",
  type: {
    name: "Composite",
    className: "OrderErrorDto",
    modelProperties: {
      id: {
        serializedName: "id",
        type: {
          name: "Uuid"
        }
      },
      code: {
        serializedName: "code",
        type: {
          name: "String"
        }
      },
      description: {
        serializedName: "description",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const OrderDetailsDto: msRest.CompositeMapper = {
  serializedName: "OrderDetailsDto",
  type: {
    name: "Composite",
    className: "OrderDetailsDto",
    modelProperties: {
      patientId: {
        serializedName: "patientId",
        type: {
          name: "String"
        }
      },
      patientCardNumber: {
        serializedName: "patientCardNumber",
        type: {
          name: "String"
        }
      },
      patientFirstname: {
        serializedName: "patientFirstname",
        type: {
          name: "String"
        }
      },
      patientSurname: {
        serializedName: "patientSurname",
        type: {
          name: "String"
        }
      },
      patientDateOfBirth: {
        serializedName: "patientDateOfBirth",
        type: {
          name: "DateTime"
        }
      },
      isEUPatient: {
        nullable: true,
        serializedName: "isEUPatient",
        type: {
          name: "Boolean"
        }
      },
      pharmacyId: {
        serializedName: "pharmacyId",
        type: {
          name: "Uuid"
        }
      },
      pharmacyName: {
        serializedName: "pharmacyName",
        type: {
          name: "String"
        }
      },
      placeId: {
        nullable: true,
        serializedName: "placeId",
        type: {
          name: "Number"
        }
      },
      releasePlace: {
        serializedName: "releasePlace",
        type: {
          name: "String"
        }
      },
      orderNumber: {
        serializedName: "orderNumber",
        type: {
          name: "String"
        }
      },
      releaseDate: {
        serializedName: "releaseDate",
        type: {
          name: "DateTime"
        }
      },
      orderReceiptDate: {
        serializedName: "orderReceiptDate",
        type: {
          name: "DateTime"
        }
      },
      productReleaseDate: {
        serializedName: "productReleaseDate",
        type: {
          name: "DateTime"
        }
      },
      realizationPeriod: {
        serializedName: "realizationPeriod",
        type: {
          name: "String"
        }
      },
      additionalEntitlementCode: {
        serializedName: "additionalEntitlementCode",
        type: {
          name: "String"
        }
      },
      additionalEntitlementFullName: {
        serializedName: "additionalEntitlementFullName",
        type: {
          name: "String"
        }
      },
      orderLines: {
        serializedName: "orderLines",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "OrderLineDetailsDto"
            }
          }
        }
      },
      summaryRefund: {
        serializedName: "summaryRefund",
        type: {
          name: "Number"
        }
      },
      summarySurcharge: {
        serializedName: "summarySurcharge",
        type: {
          name: "Number"
        }
      },
      summaryTotalValue: {
        serializedName: "summaryTotalValue",
        type: {
          name: "Number"
        }
      },
      year: {
        nullable: true,
        serializedName: "year",
        type: {
          name: "Number"
        }
      },
      month: {
        nullable: true,
        serializedName: "month",
        type: {
          name: "Number"
        }
      },
      orderState: {
        serializedName: "orderState",
        type: {
          name: "Enum",
          allowedValues: [
            "Imported",
            "Reported",
            "ApprovedByNhf",
            "MarkedToCancel",
            "SentToCancel",
            "Canceled",
            "Restored",
            "SettledNhf"
          ]
        }
      },
      taxNoteOrderState: {
        serializedName: "taxNoteOrderState",
        type: {
          name: "Enum",
          allowedValues: [
            "NotAssigned",
            "Assigned",
            "ReadyToSend",
            "SentToSap",
            "Paid",
            "NotPaid"
          ]
        }
      },
      orderErrors: {
        serializedName: "orderErrors",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "OrderErrorDto"
            }
          }
        }
      },
      realizationId: {
        serializedName: "realizationId",
        type: {
          name: "Number"
        }
      },
      canceledRealizationId: {
        nullable: true,
        serializedName: "canceledRealizationId",
        type: {
          name: "Number"
        }
      },
      restoredRealizationId: {
        nullable: true,
        serializedName: "restoredRealizationId",
        type: {
          name: "Number"
        }
      },
      cancellationReasonId: {
        nullable: true,
        serializedName: "cancellationReasonId",
        type: {
          name: "Number"
        }
      },
      cancellationReasonText: {
        serializedName: "cancellationReasonText",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const OrderDtoPagedResult: msRest.CompositeMapper = {
  serializedName: "OrderDtoPagedResult",
  type: {
    name: "Composite",
    className: "OrderDtoPagedResult",
    modelProperties: {
      queryable: {
        serializedName: "queryable",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "OrderDto"
            }
          }
        }
      },
      currentPage: {
        serializedName: "currentPage",
        type: {
          name: "Number"
        }
      },
      pageCount: {
        serializedName: "pageCount",
        type: {
          name: "Number"
        }
      },
      pageSize: {
        serializedName: "pageSize",
        type: {
          name: "Number"
        }
      },
      rowCount: {
        serializedName: "rowCount",
        type: {
          name: "Number"
        }
      }
    }
  }
};

export const OrderGroupDto: msRest.CompositeMapper = {
  serializedName: "OrderGroupDto",
  type: {
    name: "Composite",
    className: "OrderGroupDto",
    modelProperties: {
      id: {
        serializedName: "id",
        type: {
          name: "Uuid"
        }
      },
      year: {
        serializedName: "year",
        type: {
          name: "Number"
        }
      },
      month: {
        serializedName: "month",
        type: {
          name: "Number"
        }
      },
      voivodeshipId: {
        serializedName: "voivodeshipId",
        type: {
          name: "String"
        }
      },
      importDate: {
        serializedName: "importDate",
        type: {
          name: "DateTime"
        }
      },
      ordersQty: {
        serializedName: "ordersQty",
        type: {
          name: "Number"
        }
      },
      value: {
        serializedName: "value",
        type: {
          name: "Number"
        }
      },
      refund: {
        serializedName: "refund",
        type: {
          name: "Number"
        }
      },
      surcharge: {
        serializedName: "surcharge",
        type: {
          name: "Number"
        }
      },
      reportedQty: {
        serializedName: "reportedQty",
        type: {
          name: "Number"
        }
      },
      toReportQty: {
        readOnly: true,
        serializedName: "toReportQty",
        type: {
          name: "Number"
        }
      },
      toCorrectReportQty: {
        serializedName: "toCorrectReportQty",
        type: {
          name: "Number"
        }
      },
      waitingForNhfCorrectAnswerQty: {
        serializedName: "waitingForNhfCorrectAnswerQty",
        type: {
          name: "Number"
        }
      }
    }
  }
};

export const PharmacyDto: msRest.CompositeMapper = {
  serializedName: "PharmacyDto",
  type: {
    name: "Composite",
    className: "PharmacyDto",
    modelProperties: {
      id: {
        serializedName: "id",
        type: {
          name: "Uuid"
        }
      },
      name: {
        serializedName: "name",
        type: {
          name: "String"
        }
      },
      companyName: {
        serializedName: "companyName",
        type: {
          name: "String"
        }
      },
      city: {
        serializedName: "city",
        type: {
          name: "String"
        }
      },
      street: {
        serializedName: "street",
        type: {
          name: "String"
        }
      },
      voivodeshipId: {
        nullable: true,
        serializedName: "voivodeshipId",
        type: {
          name: "Number"
        }
      },
      taxNumber: {
        serializedName: "taxNumber",
        type: {
          name: "String"
        }
      },
      placeId: {
        nullable: true,
        serializedName: "placeId",
        type: {
          name: "Number"
        }
      },
      endDateOfEssityContract: {
        serializedName: "endDateOfEssityContract",
        type: {
          name: "DateTime"
        }
      },
      dateOfJoiningEssityNet: {
        serializedName: "dateOfJoiningEssityNet",
        type: {
          name: "DateTime"
        }
      },
      type: {
        serializedName: "type",
        type: {
          name: "Enum",
          allowedValues: [
            "PHARMACY",
            "MEDICAL_SHOP"
          ]
        }
      },
      endContractReasonId: {
        nullable: true,
        serializedName: "endContractReasonId",
        type: {
          name: "Number"
        }
      },
      nhfContractId: {
        nullable: true,
        serializedName: "nhfContractId",
        type: {
          name: "Number"
        }
      },
      essityPaperContractNumber: {
        serializedName: "essityPaperContractNumber",
        type: {
          name: "String"
        }
      },
      healthFundID: {
        serializedName: "healthFundID",
        type: {
          name: "String"
        }
      },
      farmapromPharmacyId: {
        nullable: true,
        serializedName: "farmapromPharmacyId",
        type: {
          name: "Number"
        }
      },
      kodKom: {
        nullable: true,
        serializedName: "kodKom",
        type: {
          name: "Number"
        }
      },
      login: {
        serializedName: "login",
        type: {
          name: "String"
        }
      },
      kam: {
        serializedName: "kam",
        type: {
          name: "String"
        }
      },
      mail: {
        serializedName: "mail",
        type: {
          name: "String"
        }
      },
      phoneNumber: {
        serializedName: "phoneNumber",
        type: {
          name: "String"
        }
      },
      salesRepresentative: {
        serializedName: "salesRepresentative",
        type: {
          name: "String"
        }
      },
      postalCode: {
        serializedName: "postalCode",
        type: {
          name: "String"
        }
      },
      district: {
        nullable: true,
        serializedName: "district",
        type: {
          name: "Number"
        }
      },
      pharmacyChain: {
        serializedName: "pharmacyChain",
        type: {
          name: "String"
        }
      },
      description: {
        serializedName: "description",
        type: {
          name: "String"
        }
      },
      iban: {
        serializedName: "iban",
        type: {
          name: "String"
        }
      },
      identifier: {
        nullable: true,
        serializedName: "identifier",
        type: {
          name: "Number"
        }
      },
      umxWarning: {
        serializedName: "umxWarning",
        type: {
          name: "Boolean"
        }
      },
      farmapromWarning: {
        serializedName: "farmapromWarning",
        type: {
          name: "Boolean"
        }
      },
      businessContinuity: {
        serializedName: "businessContinuity",
        type: {
          name: "String"
        }
      },
      accountId: {
        serializedName: "accountId",
        type: {
          name: "Uuid"
        }
      },
      portalStatus: {
        serializedName: "portalStatus",
        type: {
          name: "Enum",
          allowedValues: [
            "Sent",
            "NotSent",
            "Access"
          ]
        }
      },
      invitationCode: {
        serializedName: "invitationCode",
        type: {
          name: "String"
        }
      },
      isPharmacyHasNhfContract: {
        serializedName: "isPharmacyHasNhfContract",
        type: {
          name: "Boolean"
        }
      },
      createdDate: {
        serializedName: "createdDate",
        type: {
          name: "DateTime"
        }
      }
    }
  }
};

export const PharmacyDtoPagedResult: msRest.CompositeMapper = {
  serializedName: "PharmacyDtoPagedResult",
  type: {
    name: "Composite",
    className: "PharmacyDtoPagedResult",
    modelProperties: {
      queryable: {
        serializedName: "queryable",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "PharmacyDto"
            }
          }
        }
      },
      currentPage: {
        serializedName: "currentPage",
        type: {
          name: "Number"
        }
      },
      pageCount: {
        serializedName: "pageCount",
        type: {
          name: "Number"
        }
      },
      pageSize: {
        serializedName: "pageSize",
        type: {
          name: "Number"
        }
      },
      rowCount: {
        serializedName: "rowCount",
        type: {
          name: "Number"
        }
      }
    }
  }
};

export const PharmacyTaxNoteOrderDetailsDto: msRest.CompositeMapper = {
  serializedName: "PharmacyTaxNoteOrderDetailsDto",
  type: {
    name: "Composite",
    className: "PharmacyTaxNoteOrderDetailsDto",
    modelProperties: {
      id: {
        serializedName: "id",
        type: {
          name: "Uuid"
        }
      },
      realizationId: {
        serializedName: "realizationId",
        type: {
          name: "Number"
        }
      },
      downloadId: {
        serializedName: "downloadId",
        type: {
          name: "Number"
        }
      },
      kodKom: {
        serializedName: "kodKom",
        type: {
          name: "Number"
        }
      },
      documentType: {
        serializedName: "documentType",
        type: {
          name: "String"
        }
      },
      orderNumber: {
        serializedName: "orderNumber",
        type: {
          name: "String"
        }
      },
      orderDate: {
        serializedName: "orderDate",
        type: {
          name: "DateTime"
        }
      },
      realizationMonthsQty: {
        serializedName: "realizationMonthsQty",
        type: {
          name: "Number"
        }
      },
      orderRealizationStartDate: {
        serializedName: "orderRealizationStartDate",
        type: {
          name: "DateTime"
        }
      },
      pesel: {
        serializedName: "pesel",
        type: {
          name: "String"
        }
      },
      value: {
        serializedName: "value",
        type: {
          name: "Number"
        }
      },
      refund: {
        serializedName: "refund",
        type: {
          name: "Number"
        }
      },
      reported: {
        serializedName: "reported",
        type: {
          name: "Boolean"
        }
      },
      reportDate: {
        serializedName: "reportDate",
        type: {
          name: "DateTime"
        }
      },
      internalNumber: {
        nullable: true,
        serializedName: "internalNumber",
        type: {
          name: "Number"
        }
      },
      pharmacyId: {
        serializedName: "pharmacyId",
        type: {
          name: "Uuid"
        }
      },
      pharmacyName: {
        serializedName: "pharmacyName",
        type: {
          name: "String"
        }
      },
      orderState: {
        serializedName: "orderState",
        type: {
          name: "Enum",
          allowedValues: [
            "Imported",
            "Reported",
            "ApprovedByNhf",
            "MarkedToCancel",
            "SentToCancel",
            "Canceled",
            "Restored",
            "SettledNhf"
          ]
        }
      }
    }
  }
};

export const PharmacyTaxNoteDetailsDto: msRest.CompositeMapper = {
  serializedName: "PharmacyTaxNoteDetailsDto",
  type: {
    name: "Composite",
    className: "PharmacyTaxNoteDetailsDto",
    modelProperties: {
      id: {
        serializedName: "id",
        type: {
          name: "Uuid"
        }
      },
      pharmacy: {
        serializedName: "pharmacy",
        type: {
          name: "Composite",
          className: "PharmacyDto"
        }
      },
      internalNumber: {
        serializedName: "internalNumber",
        type: {
          name: "String"
        }
      },
      pharmacyTaxNoteGroupId: {
        serializedName: "pharmacyTaxNoteGroupId",
        type: {
          name: "Uuid"
        }
      },
      noteDate: {
        serializedName: "noteDate",
        type: {
          name: "DateTime"
        }
      },
      noteNumber: {
        serializedName: "noteNumber",
        type: {
          name: "String"
        }
      },
      noteValue: {
        serializedName: "noteValue",
        type: {
          name: "Number"
        }
      },
      settledOrdersValue: {
        serializedName: "settledOrdersValue",
        type: {
          name: "Number"
        }
      },
      assignedOrdersValue: {
        serializedName: "assignedOrdersValue",
        type: {
          name: "Number"
        }
      },
      taxNoteType: {
        serializedName: "taxNoteType",
        type: {
          name: "Enum",
          allowedValues: [
            "Main",
            "Correction",
            "Loss"
          ]
        }
      },
      taxNoteSource: {
        serializedName: "taxNoteSource",
        type: {
          name: "Enum",
          allowedValues: [
            "Phoenix",
            "Portal"
          ]
        }
      },
      internalTaxNoteType: {
        serializedName: "internalTaxNoteType",
        type: {
          name: "Enum",
          allowedValues: [
            "Main",
            "Correction",
            "Debit",
            "Loss",
            "Return",
            "PayLoss",
            "Overpay"
          ]
        }
      },
      year: {
        serializedName: "year",
        type: {
          name: "Number"
        }
      },
      month: {
        serializedName: "month",
        type: {
          name: "Number"
        }
      },
      fileName: {
        serializedName: "fileName",
        type: {
          name: "String"
        }
      },
      fileExtension: {
        serializedName: "fileExtension",
        type: {
          name: "String"
        }
      },
      mimeType: {
        serializedName: "mimeType",
        type: {
          name: "String"
        }
      },
      documentsData: {
        serializedName: "documentsData",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "FileData"
            }
          }
        }
      },
      orders: {
        serializedName: "orders",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "PharmacyTaxNoteOrderDetailsDto"
            }
          }
        }
      },
      mainPharmacyTaxNoteId: {
        serializedName: "mainPharmacyTaxNoteId",
        type: {
          name: "Uuid"
        }
      },
      mainPharmacyTaxNoteInternalNumber: {
        serializedName: "mainPharmacyTaxNoteInternalNumber",
        type: {
          name: "String"
        }
      },
      isDocumentationComplete: {
        serializedName: "isDocumentationComplete",
        type: {
          name: "Boolean"
        }
      },
      note: {
        serializedName: "note",
        type: {
          name: "String"
        }
      },
      limitedEditMode: {
        serializedName: "limitedEditMode",
        type: {
          name: "Boolean"
        }
      }
    }
  }
};

export const PharmacyTaxNoteGroupDto: msRest.CompositeMapper = {
  serializedName: "PharmacyTaxNoteGroupDto",
  type: {
    name: "Composite",
    className: "PharmacyTaxNoteGroupDto",
    modelProperties: {
      id: {
        serializedName: "id",
        type: {
          name: "Uuid"
        }
      },
      pharmacyId: {
        serializedName: "pharmacyId",
        type: {
          name: "Uuid"
        }
      },
      pharmacyName: {
        serializedName: "pharmacyName",
        type: {
          name: "String"
        }
      },
      placeId: {
        serializedName: "placeId",
        type: {
          name: "Number"
        }
      },
      year: {
        serializedName: "year",
        type: {
          name: "Number"
        }
      },
      month: {
        serializedName: "month",
        type: {
          name: "Number"
        }
      },
      voivodeshipId: {
        serializedName: "voivodeshipId",
        type: {
          name: "Number"
        }
      },
      noteValue: {
        serializedName: "noteValue",
        type: {
          name: "Number"
        }
      },
      settledOrdersValue: {
        serializedName: "settledOrdersValue",
        type: {
          name: "Number"
        }
      },
      assignedOrdersValue: {
        serializedName: "assignedOrdersValue",
        type: {
          name: "Number"
        }
      },
      allOrdersValue: {
        serializedName: "allOrdersValue",
        type: {
          name: "Number"
        }
      },
      correctionValue: {
        serializedName: "correctionValue",
        type: {
          name: "Number"
        }
      },
      lossValue: {
        serializedName: "lossValue",
        type: {
          name: "Number"
        }
      },
      toPay: {
        serializedName: "toPay",
        type: {
          name: "Number"
        }
      },
      toReturn: {
        serializedName: "toReturn",
        type: {
          name: "Number"
        }
      },
      sentToSap: {
        serializedName: "sentToSap",
        type: {
          name: "Number"
        }
      },
      returnProperty: {
        serializedName: "return",
        type: {
          name: "Number"
        }
      },
      paid: {
        serializedName: "paid",
        type: {
          name: "Number"
        }
      },
      balance: {
        serializedName: "balance",
        type: {
          name: "Number"
        }
      },
      paymentState: {
        serializedName: "paymentState",
        type: {
          name: "Enum",
          allowedValues: [
            "Created",
            "ReadyToSend",
            "SentToSap",
            "Paid",
            "Compensated"
          ]
        }
      },
      freezed: {
        readOnly: true,
        serializedName: "freezed",
        type: {
          name: "Boolean"
        }
      },
      isGroupValidAccordingToFinancialLogic: {
        readOnly: true,
        serializedName: "isGroupValidAccordingToFinancialLogic",
        type: {
          name: "Boolean"
        }
      }
    }
  }
};

export const PharmacyTaxNoteGroupDtoPagedResult: msRest.CompositeMapper = {
  serializedName: "PharmacyTaxNoteGroupDtoPagedResult",
  type: {
    name: "Composite",
    className: "PharmacyTaxNoteGroupDtoPagedResult",
    modelProperties: {
      queryable: {
        serializedName: "queryable",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "PharmacyTaxNoteGroupDto"
            }
          }
        }
      },
      currentPage: {
        serializedName: "currentPage",
        type: {
          name: "Number"
        }
      },
      pageCount: {
        serializedName: "pageCount",
        type: {
          name: "Number"
        }
      },
      pageSize: {
        serializedName: "pageSize",
        type: {
          name: "Number"
        }
      },
      rowCount: {
        serializedName: "rowCount",
        type: {
          name: "Number"
        }
      }
    }
  }
};

export const PharmacyTaxNotePaymentConfirmationChangeStateCommand: msRest.CompositeMapper = {
  serializedName: "PharmacyTaxNotePaymentConfirmationChangeStateCommand",
  type: {
    name: "Composite",
    className: "PharmacyTaxNotePaymentConfirmationChangeStateCommand",
    modelProperties: {
      pharmacyTaxNotePaymentId: {
        serializedName: "pharmacyTaxNotePaymentId",
        type: {
          name: "Uuid"
        }
      },
      confirmationState: {
        serializedName: "confirmationState",
        type: {
          name: "Enum",
          allowedValues: [
            "CONFIRMED",
            "REFUSED",
            "RECOVERED"
          ]
        }
      },
      refuseDate: {
        serializedName: "refuseDate",
        type: {
          name: "DateTime"
        }
      },
      recoverDate: {
        serializedName: "recoverDate",
        type: {
          name: "DateTime"
        }
      }
    }
  }
};

export const ProductDto: msRest.CompositeMapper = {
  serializedName: "ProductDto",
  type: {
    name: "Composite",
    className: "ProductDto",
    modelProperties: {
      id: {
        serializedName: "id",
        type: {
          name: "Uuid"
        }
      },
      productId: {
        serializedName: "productId",
        type: {
          name: "Number"
        }
      },
      productVersionCode: {
        serializedName: "productVersionCode",
        type: {
          name: "String"
        }
      },
      productVersionNFZCode: {
        serializedName: "productVersionNFZCode",
        type: {
          name: "String"
        }
      },
      productVersionPlace: {
        serializedName: "productVersionPlace",
        type: {
          name: "String"
        }
      },
      productName: {
        serializedName: "productName",
        type: {
          name: "String"
        }
      },
      priceListGroup: {
        serializedName: "priceListGroup",
        type: {
          name: "Enum",
          allowedValues: [
            "Auxiliaries",
            "OrthopedicProducts"
          ]
        }
      }
    }
  }
};

export const ProductPriceDto: msRest.CompositeMapper = {
  serializedName: "ProductPriceDto",
  type: {
    name: "Composite",
    className: "ProductPriceDto",
    modelProperties: {
      id: {
        serializedName: "id",
        type: {
          name: "Uuid"
        }
      },
      productNHFCode: {
        serializedName: "productNHFCode",
        type: {
          name: "Number"
        }
      },
      productVersionNFZCode: {
        serializedName: "productVersionNFZCode",
        type: {
          name: "String"
        }
      },
      productName: {
        serializedName: "productName",
        type: {
          name: "String"
        }
      },
      price: {
        serializedName: "price",
        type: {
          name: "Number"
        }
      },
      unit: {
        nullable: true,
        serializedName: "unit",
        type: {
          name: "Boolean"
        }
      },
      qtyLimit: {
        nullable: true,
        serializedName: "qtyLimit",
        type: {
          name: "Number"
        }
      },
      producent: {
        serializedName: "producent",
        type: {
          name: "String"
        }
      },
      model: {
        serializedName: "model",
        type: {
          name: "String"
        }
      },
      individualOrder: {
        nullable: true,
        serializedName: "individualOrder",
        type: {
          name: "Boolean"
        }
      }
    }
  }
};

export const ProductPriceDtoPagedResult: msRest.CompositeMapper = {
  serializedName: "ProductPriceDtoPagedResult",
  type: {
    name: "Composite",
    className: "ProductPriceDtoPagedResult",
    modelProperties: {
      queryable: {
        serializedName: "queryable",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "ProductPriceDto"
            }
          }
        }
      },
      currentPage: {
        serializedName: "currentPage",
        type: {
          name: "Number"
        }
      },
      pageCount: {
        serializedName: "pageCount",
        type: {
          name: "Number"
        }
      },
      pageSize: {
        serializedName: "pageSize",
        type: {
          name: "Number"
        }
      },
      rowCount: {
        serializedName: "rowCount",
        type: {
          name: "Number"
        }
      }
    }
  }
};

export const RecountPharmacyTaxNoteCorrectionCommand: msRest.CompositeMapper = {
  serializedName: "RecountPharmacyTaxNoteCorrectionCommand",
  type: {
    name: "Composite",
    className: "RecountPharmacyTaxNoteCorrectionCommand",
    modelProperties: {
      pharmacyTaxNoteId: {
        serializedName: "pharmacyTaxNoteId",
        type: {
          name: "Uuid"
        }
      }
    }
  }
};

export const RecountPharmacyTaxNoteOverpayCommand: msRest.CompositeMapper = {
  serializedName: "RecountPharmacyTaxNoteOverpayCommand",
  type: {
    name: "Composite",
    className: "RecountPharmacyTaxNoteOverpayCommand",
    modelProperties: {
      pharmacyTaxNoteId: {
        serializedName: "pharmacyTaxNoteId",
        type: {
          name: "Uuid"
        }
      }
    }
  }
};

export const RegisterAccountCommand: msRest.CompositeMapper = {
  serializedName: "RegisterAccountCommand",
  type: {
    name: "Composite",
    className: "RegisterAccountCommand",
    modelProperties: {
      password: {
        serializedName: "password",
        type: {
          name: "String"
        }
      },
      accountRoles: {
        serializedName: "accountRoles",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "AccountRoleDto"
            }
          }
        }
      },
      userPrincipalName: {
        serializedName: "userPrincipalName",
        type: {
          name: "String"
        }
      },
      displayName: {
        serializedName: "displayName",
        type: {
          name: "String"
        }
      },
      givenName: {
        serializedName: "givenName",
        type: {
          name: "String"
        }
      },
      surname: {
        serializedName: "surname",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const RegisterPharmacyCommand: msRest.CompositeMapper = {
  serializedName: "RegisterPharmacyCommand",
  type: {
    name: "Composite",
    className: "RegisterPharmacyCommand",
    modelProperties: {
      password: {
        serializedName: "password",
        type: {
          name: "String"
        }
      },
      pharmacyId: {
        serializedName: "pharmacyId",
        type: {
          name: "Uuid"
        }
      },
      invitationCode: {
        serializedName: "invitationCode",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const RestoreCommand: msRest.CompositeMapper = {
  serializedName: "RestoreCommand",
  type: {
    name: "Composite",
    className: "RestoreCommand",
    modelProperties: {
      orderId: {
        serializedName: "orderId",
        type: {
          name: "Uuid"
        }
      }
    }
  }
};

export const ReturnDocument: msRest.CompositeMapper = {
  serializedName: "ReturnDocument",
  type: {
    name: "Composite",
    className: "ReturnDocument",
    modelProperties: {
      orderId: {
        serializedName: "orderId",
        type: {
          name: "Uuid"
        }
      },
      orderNumber: {
        serializedName: "orderNumber",
        type: {
          name: "String"
        }
      },
      pesel: {
        serializedName: "pesel",
        type: {
          name: "String"
        }
      },
      errorMessage: {
        serializedName: "errorMessage",
        type: {
          name: "String"
        }
      },
      orderRefund: {
        serializedName: "orderRefund",
        type: {
          name: "Number"
        }
      }
    }
  }
};

export const SaveAccountSettingsCommand: msRest.CompositeMapper = {
  serializedName: "SaveAccountSettingsCommand",
  type: {
    name: "Composite",
    className: "SaveAccountSettingsCommand",
    modelProperties: {
      voivodeshipFilterDictionaryIds: {
        serializedName: "voivodeshipFilterDictionaryIds",
        type: {
          name: "String"
        }
      },
      settlementPeriodFilterDictionaryValueCodeName: {
        serializedName: "settlementPeriodFilterDictionaryValueCodeName",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const SaveAndGenerateReturnDocumentCommand: msRest.CompositeMapper = {
  serializedName: "SaveAndGenerateReturnDocumentCommand",
  type: {
    name: "Composite",
    className: "SaveAndGenerateReturnDocumentCommand",
    modelProperties: {
      pharmacyTaxNoteGroupId: {
        serializedName: "pharmacyTaxNoteGroupId",
        type: {
          name: "Uuid"
        }
      },
      returnDocuments: {
        serializedName: "returnDocuments",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "ReturnDocument"
            }
          }
        }
      }
    }
  }
};

export const SendToSAPCommand: msRest.CompositeMapper = {
  serializedName: "SendToSAPCommand",
  type: {
    name: "Composite",
    className: "SendToSAPCommand",
    modelProperties: {
      checked: {
        serializedName: "checked",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Uuid"
            }
          }
        }
      }
    }
  }
};

export const VoivodeshipPaymentGroupWithHashDto: msRest.CompositeMapper = {
  serializedName: "VoivodeshipPaymentGroupWithHashDto",
  type: {
    name: "Composite",
    className: "VoivodeshipPaymentGroupWithHashDto",
    modelProperties: {
      id: {
        serializedName: "id",
        type: {
          name: "Uuid"
        }
      },
      hashCode: {
        serializedName: "hashCode",
        type: {
          name: "Number"
        }
      }
    }
  }
};

export const SendToSapAgainVoivodeshipPaymentGroupCommand: msRest.CompositeMapper = {
  serializedName: "SendToSapAgainVoivodeshipPaymentGroupCommand",
  type: {
    name: "Composite",
    className: "SendToSapAgainVoivodeshipPaymentGroupCommand",
    modelProperties: {
      voivodeshipPaymentGroupsWithHash: {
        serializedName: "voivodeshipPaymentGroupsWithHash",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "VoivodeshipPaymentGroupWithHashDto"
            }
          }
        }
      },
      postingDate: {
        serializedName: "postingDate",
        type: {
          name: "DateTime"
        }
      }
    }
  }
};

export const SendToSapVoivodeshipPaymentGroupCommand: msRest.CompositeMapper = {
  serializedName: "SendToSapVoivodeshipPaymentGroupCommand",
  type: {
    name: "Composite",
    className: "SendToSapVoivodeshipPaymentGroupCommand",
    modelProperties: {
      voivodeshipPaymentGroupsWithHash: {
        serializedName: "voivodeshipPaymentGroupsWithHash",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "VoivodeshipPaymentGroupWithHashDto"
            }
          }
        }
      },
      postingDate: {
        serializedName: "postingDate",
        type: {
          name: "DateTime"
        }
      }
    }
  }
};

export const SourceDocumentDto: msRest.CompositeMapper = {
  serializedName: "SourceDocumentDto",
  type: {
    name: "Composite",
    className: "SourceDocumentDto",
    modelProperties: {
      id: {
        serializedName: "id",
        type: {
          name: "Uuid"
        }
      },
      source: {
        serializedName: "source",
        type: {
          name: "Enum",
          allowedValues: [
            "PHARMACIES_IMPORT",
            "FARMAPROM_PHARMACIES_IMPORT",
            "UMX_PHARMACIES_IMPORT",
            "ORDERS_IMPORT",
            "PRICE_LIST_IMPORT",
            "ZPOSP_EXPORT",
            "PHARMACIES_IMPORT_TEMPLATE",
            "NHF_STATE_IMPORT",
            "NOTE_TEMPLATE_IMPORT",
            "NOTE_EXPORT",
            "PAYMENT_EXPORT",
            "PAYMENT_CONFIRMATIONS_IMPORT",
            "RETURN_DOCUMENT",
            "ZPOZR_IMPORT"
          ]
        }
      },
      category: {
        serializedName: "category",
        type: {
          name: "Enum",
          allowedValues: [
            "IMPORT",
            "EXPORT"
          ]
        }
      },
      createdDate: {
        serializedName: "createdDate",
        type: {
          name: "DateTime"
        }
      },
      name: {
        serializedName: "name",
        type: {
          name: "String"
        }
      },
      createdBy: {
        serializedName: "createdBy",
        type: {
          name: "String"
        }
      },
      createdByUserId: {
        serializedName: "createdByUserId",
        type: {
          name: "Uuid"
        }
      }
    }
  }
};

export const SourceDocumentDtoPagedResult: msRest.CompositeMapper = {
  serializedName: "SourceDocumentDtoPagedResult",
  type: {
    name: "Composite",
    className: "SourceDocumentDtoPagedResult",
    modelProperties: {
      queryable: {
        serializedName: "queryable",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "SourceDocumentDto"
            }
          }
        }
      },
      currentPage: {
        serializedName: "currentPage",
        type: {
          name: "Number"
        }
      },
      pageCount: {
        serializedName: "pageCount",
        type: {
          name: "Number"
        }
      },
      pageSize: {
        serializedName: "pageSize",
        type: {
          name: "Number"
        }
      },
      rowCount: {
        serializedName: "rowCount",
        type: {
          name: "Number"
        }
      }
    }
  }
};

export const TemplateNoteDto: msRest.CompositeMapper = {
  serializedName: "TemplateNoteDto",
  type: {
    name: "Composite",
    className: "TemplateNoteDto",
    modelProperties: {
      id: {
        serializedName: "id",
        type: {
          name: "Uuid"
        }
      },
      voivodeshipId: {
        serializedName: "voivodeshipId",
        type: {
          name: "Number"
        }
      },
      templateId: {
        serializedName: "templateId",
        type: {
          name: "Number"
        }
      },
      templateCorrectId: {
        nullable: true,
        serializedName: "templateCorrectId",
        type: {
          name: "Number"
        }
      },
      year: {
        serializedName: "year",
        type: {
          name: "Number"
        }
      },
      month: {
        serializedName: "month",
        type: {
          name: "Number"
        }
      },
      noteType: {
        serializedName: "noteType",
        type: {
          name: "Enum",
          allowedValues: [
            "Main",
            "Correction"
          ]
        }
      },
      noteName: {
        serializedName: "noteName",
        type: {
          name: "String"
        }
      },
      noteDate: {
        serializedName: "noteDate",
        type: {
          name: "DateTime"
        }
      },
      totalValue: {
        nullable: true,
        serializedName: "totalValue",
        type: {
          name: "Number"
        }
      },
      refund: {
        nullable: true,
        serializedName: "refund",
        type: {
          name: "Number"
        }
      },
      surcharge: {
        nullable: true,
        serializedName: "surcharge",
        type: {
          name: "Number"
        }
      },
      noteCreated: {
        serializedName: "noteCreated",
        type: {
          name: "Boolean"
        }
      },
      noteFileId: {
        serializedName: "noteFileId",
        type: {
          name: "Uuid"
        }
      },
      sent: {
        serializedName: "sent",
        type: {
          name: "Boolean"
        }
      }
    }
  }
};

export const TemplateNoteInfoDto: msRest.CompositeMapper = {
  serializedName: "TemplateNoteInfoDto",
  type: {
    name: "Composite",
    className: "TemplateNoteInfoDto",
    modelProperties: {
      id: {
        serializedName: "id",
        type: {
          name: "Uuid"
        }
      },
      templateId: {
        serializedName: "templateId",
        type: {
          name: "Number"
        }
      },
      noteName: {
        serializedName: "noteName",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const UmxPharmacyDto: msRest.CompositeMapper = {
  serializedName: "UmxPharmacyDto",
  type: {
    name: "Composite",
    className: "UmxPharmacyDto",
    modelProperties: {
      id: {
        serializedName: "id",
        type: {
          name: "Uuid"
        }
      },
      technicalCode: {
        serializedName: "technicalCode",
        type: {
          name: "String"
        }
      },
      dateFrom: {
        serializedName: "dateFrom",
        type: {
          name: "DateTime"
        }
      },
      dateTo: {
        serializedName: "dateTo",
        type: {
          name: "DateTime"
        }
      },
      kodKom: {
        serializedName: "kodKom",
        type: {
          name: "Number"
        }
      },
      name: {
        serializedName: "name",
        type: {
          name: "String"
        }
      },
      city: {
        serializedName: "city",
        type: {
          name: "String"
        }
      },
      postalCode: {
        serializedName: "postalCode",
        type: {
          name: "String"
        }
      },
      voivodeshipId: {
        serializedName: "voivodeshipId",
        type: {
          name: "Number"
        }
      },
      importId: {
        serializedName: "importId",
        type: {
          name: "Uuid"
        }
      },
      identifier: {
        serializedName: "identifier",
        type: {
          name: "Number"
        }
      },
      street: {
        serializedName: "street",
        type: {
          name: "String"
        }
      },
      healthFundID: {
        serializedName: "healthFundID",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const UmxPharmacyDtoPagedResult: msRest.CompositeMapper = {
  serializedName: "UmxPharmacyDtoPagedResult",
  type: {
    name: "Composite",
    className: "UmxPharmacyDtoPagedResult",
    modelProperties: {
      results: {
        serializedName: "results",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "UmxPharmacyDto"
            }
          }
        }
      },
      rowCount: {
        serializedName: "rowCount",
        type: {
          name: "Number"
        }
      },
      pageSize: {
        serializedName: "pageSize",
        type: {
          name: "Number"
        }
      },
      currentPage: {
        serializedName: "currentPage",
        type: {
          name: "Number"
        }
      },
      totalPagesNumber: {
        readOnly: true,
        serializedName: "totalPagesNumber",
        type: {
          name: "Number"
        }
      },
      hasPreviousPage: {
        readOnly: true,
        serializedName: "hasPreviousPage",
        type: {
          name: "Boolean"
        }
      },
      hasNextPage: {
        readOnly: true,
        serializedName: "hasNextPage",
        type: {
          name: "Boolean"
        }
      },
      firstRowOnPage: {
        readOnly: true,
        serializedName: "firstRowOnPage",
        type: {
          name: "Number"
        }
      },
      lastRowOnPage: {
        readOnly: true,
        serializedName: "lastRowOnPage",
        type: {
          name: "Number"
        }
      }
    }
  }
};

export const UpdateLossPharmacyTaxNoteCommand: msRest.CompositeMapper = {
  serializedName: "UpdateLossPharmacyTaxNoteCommand",
  type: {
    name: "Composite",
    className: "UpdateLossPharmacyTaxNoteCommand",
    modelProperties: {
      pharmacyTaxNoteId: {
        serializedName: "pharmacyTaxNoteId",
        type: {
          name: "Uuid"
        }
      },
      noteDate: {
        serializedName: "noteDate",
        type: {
          name: "DateTime"
        }
      },
      noteValue: {
        serializedName: "noteValue",
        type: {
          name: "Number"
        }
      },
      note: {
        serializedName: "note",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const UpdateNoteResponseDto: msRest.CompositeMapper = {
  serializedName: "UpdateNoteResponseDto",
  type: {
    name: "Composite",
    className: "UpdateNoteResponseDto",
    modelProperties: {
      pharmacyId: {
        serializedName: "pharmacyId",
        type: {
          name: "Uuid"
        }
      },
      pharmacyTaxNoteGroupId: {
        serializedName: "pharmacyTaxNoteGroupId",
        type: {
          name: "Uuid"
        }
      }
    }
  }
};

export const UpdatePayLossPharmacyTaxNoteCommand: msRest.CompositeMapper = {
  serializedName: "UpdatePayLossPharmacyTaxNoteCommand",
  type: {
    name: "Composite",
    className: "UpdatePayLossPharmacyTaxNoteCommand",
    modelProperties: {
      pharmacyTaxNoteId: {
        serializedName: "pharmacyTaxNoteId",
        type: {
          name: "Uuid"
        }
      },
      noteDate: {
        serializedName: "noteDate",
        type: {
          name: "DateTime"
        }
      },
      noteValue: {
        serializedName: "noteValue",
        type: {
          name: "Number"
        }
      },
      note: {
        serializedName: "note",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const UpdatePharmacyCommand: msRest.CompositeMapper = {
  serializedName: "UpdatePharmacyCommand",
  type: {
    name: "Composite",
    className: "UpdatePharmacyCommand",
    modelProperties: {
      id: {
        serializedName: "id",
        type: {
          name: "Uuid"
        }
      },
      name: {
        serializedName: "name",
        type: {
          name: "String"
        }
      },
      companyName: {
        serializedName: "companyName",
        type: {
          name: "String"
        }
      },
      city: {
        serializedName: "city",
        type: {
          name: "String"
        }
      },
      street: {
        serializedName: "street",
        type: {
          name: "String"
        }
      },
      voivodeshipId: {
        nullable: true,
        serializedName: "voivodeshipId",
        type: {
          name: "Number"
        }
      },
      taxNumber: {
        serializedName: "taxNumber",
        type: {
          name: "String"
        }
      },
      type: {
        serializedName: "type",
        type: {
          name: "Enum",
          allowedValues: [
            "PHARMACY",
            "MEDICAL_SHOP"
          ]
        }
      },
      endDateOfEssityContract: {
        serializedName: "endDateOfEssityContract",
        type: {
          name: "DateTime"
        }
      },
      dateOfJoiningEssityNet: {
        serializedName: "dateOfJoiningEssityNet",
        type: {
          name: "DateTime"
        }
      },
      endContractReasonId: {
        nullable: true,
        serializedName: "endContractReasonId",
        type: {
          name: "Number"
        }
      },
      nhfContractId: {
        nullable: true,
        serializedName: "nhfContractId",
        type: {
          name: "Number"
        }
      },
      essityPaperContractNumber: {
        serializedName: "essityPaperContractNumber",
        type: {
          name: "String"
        }
      },
      healthFundID: {
        serializedName: "healthFundID",
        type: {
          name: "String"
        }
      },
      farmapromPharmacyId: {
        nullable: true,
        serializedName: "farmapromPharmacyId",
        type: {
          name: "Number"
        }
      },
      kodKom: {
        nullable: true,
        serializedName: "kodKom",
        type: {
          name: "Number"
        }
      },
      login: {
        serializedName: "login",
        type: {
          name: "String"
        }
      },
      kam: {
        serializedName: "kam",
        type: {
          name: "String"
        }
      },
      mail: {
        serializedName: "mail",
        type: {
          name: "String"
        }
      },
      phoneNumber: {
        serializedName: "phoneNumber",
        type: {
          name: "String"
        }
      },
      salesRepresentative: {
        serializedName: "salesRepresentative",
        type: {
          name: "String"
        }
      },
      postalCode: {
        serializedName: "postalCode",
        type: {
          name: "String"
        }
      },
      district: {
        nullable: true,
        serializedName: "district",
        type: {
          name: "Number"
        }
      },
      pharmacyChain: {
        serializedName: "pharmacyChain",
        type: {
          name: "String"
        }
      },
      description: {
        serializedName: "description",
        type: {
          name: "String"
        }
      },
      iban: {
        serializedName: "iban",
        type: {
          name: "String"
        }
      },
      identifier: {
        nullable: true,
        serializedName: "identifier",
        type: {
          name: "Number"
        }
      },
      businessContinuity: {
        serializedName: "businessContinuity",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const UpdatePharmacyTaxNoteCommand: msRest.CompositeMapper = {
  serializedName: "UpdatePharmacyTaxNoteCommand",
  type: {
    name: "Composite",
    className: "UpdatePharmacyTaxNoteCommand",
    modelProperties: {
      pharmacyTaxNoteId: {
        serializedName: "pharmacyTaxNoteId",
        type: {
          name: "Uuid"
        }
      },
      noteDate: {
        serializedName: "noteDate",
        type: {
          name: "DateTime"
        }
      },
      pharmacyTaxNoteOrders: {
        serializedName: "pharmacyTaxNoteOrders",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Uuid"
            }
          }
        }
      },
      note: {
        serializedName: "note",
        type: {
          name: "String"
        }
      },
      noteNumber: {
        serializedName: "noteNumber",
        type: {
          name: "String"
        }
      },
      noteValue: {
        serializedName: "noteValue",
        type: {
          name: "Number"
        }
      },
      documents: {
        serializedName: "documents",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "FileData"
            }
          }
        }
      },
      isDocumentationComplete: {
        serializedName: "isDocumentationComplete",
        type: {
          name: "Boolean"
        }
      }
    }
  }
};

export const UpdatePharmacyTaxNoteCommandForPharmacy: msRest.CompositeMapper = {
  serializedName: "UpdatePharmacyTaxNoteCommandForPharmacy",
  type: {
    name: "Composite",
    className: "UpdatePharmacyTaxNoteCommandForPharmacy",
    modelProperties: {
      pharmacyTaxNoteId: {
        serializedName: "pharmacyTaxNoteId",
        type: {
          name: "Uuid"
        }
      },
      noteDate: {
        serializedName: "noteDate",
        type: {
          name: "DateTime"
        }
      },
      noteNumber: {
        serializedName: "noteNumber",
        type: {
          name: "String"
        }
      },
      noteValue: {
        serializedName: "noteValue",
        type: {
          name: "Number"
        }
      },
      fileName: {
        serializedName: "fileName",
        type: {
          name: "String"
        }
      },
      fileExtension: {
        serializedName: "fileExtension",
        type: {
          name: "String"
        }
      },
      mimeType: {
        serializedName: "mimeType",
        type: {
          name: "String"
        }
      },
      data: {
        serializedName: "data",
        type: {
          name: "ByteArray"
        }
      }
    }
  }
};

export const UpdatePharmacyTaxNoteCorrectionCommand: msRest.CompositeMapper = {
  serializedName: "UpdatePharmacyTaxNoteCorrectionCommand",
  type: {
    name: "Composite",
    className: "UpdatePharmacyTaxNoteCorrectionCommand",
    modelProperties: {
      pharmacyTaxNoteId: {
        serializedName: "pharmacyTaxNoteId",
        type: {
          name: "Uuid"
        }
      },
      noteDate: {
        serializedName: "noteDate",
        type: {
          name: "DateTime"
        }
      },
      pharmacyTaxNoteOrders: {
        serializedName: "pharmacyTaxNoteOrders",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Uuid"
            }
          }
        }
      },
      note: {
        serializedName: "note",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const UpdatePharmacyTaxNoteGroupManualOperationCommand: msRest.CompositeMapper = {
  serializedName: "UpdatePharmacyTaxNoteGroupManualOperationCommand",
  type: {
    name: "Composite",
    className: "UpdatePharmacyTaxNoteGroupManualOperationCommand",
    modelProperties: {
      id: {
        serializedName: "id",
        type: {
          name: "Uuid"
        }
      },
      manualOperationType: {
        serializedName: "manualOperationType",
        type: {
          name: "Enum",
          allowedValues: [
            "Return",
            "Payment"
          ]
        }
      },
      value: {
        serializedName: "value",
        type: {
          name: "Number"
        }
      },
      date: {
        serializedName: "date",
        type: {
          name: "DateTime"
        }
      }
    }
  }
};

export const UpdatePharmacyTaxNotePaymentConfirmationCommand: msRest.CompositeMapper = {
  serializedName: "UpdatePharmacyTaxNotePaymentConfirmationCommand",
  type: {
    name: "Composite",
    className: "UpdatePharmacyTaxNotePaymentConfirmationCommand",
    modelProperties: {
      pharmacyTaxNotePaymentConfirmationId: {
        serializedName: "pharmacyTaxNotePaymentConfirmationId",
        type: {
          name: "Uuid"
        }
      },
      amount: {
        serializedName: "amount",
        type: {
          name: "Number"
        }
      },
      confirmationDate: {
        serializedName: "confirmationDate",
        type: {
          name: "DateTime"
        }
      }
    }
  }
};

export const UpdatePharmacyTaxOverpayNoteCommand: msRest.CompositeMapper = {
  serializedName: "UpdatePharmacyTaxOverpayNoteCommand",
  type: {
    name: "Composite",
    className: "UpdatePharmacyTaxOverpayNoteCommand",
    modelProperties: {
      pharmacyTaxNoteId: {
        serializedName: "pharmacyTaxNoteId",
        type: {
          name: "Uuid"
        }
      },
      noteDate: {
        serializedName: "noteDate",
        type: {
          name: "DateTime"
        }
      },
      note: {
        serializedName: "note",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const UpdateSAPIdentifierCommand: msRest.CompositeMapper = {
  serializedName: "UpdateSAPIdentifierCommand",
  type: {
    name: "Composite",
    className: "UpdateSAPIdentifierCommand",
    modelProperties: {
      pharmacyId: {
        serializedName: "pharmacyId",
        type: {
          name: "Uuid"
        }
      },
      sapId: {
        serializedName: "sapId",
        type: {
          name: "Number"
        }
      }
    }
  }
};

export const UpdateVoivodeshipCommand: msRest.CompositeMapper = {
  serializedName: "UpdateVoivodeshipCommand",
  type: {
    name: "Composite",
    className: "UpdateVoivodeshipCommand",
    modelProperties: {
      id: {
        serializedName: "id",
        type: {
          name: "Uuid"
        }
      },
      serviceProviderCode: {
        serializedName: "serviceProviderCode",
        type: {
          name: "String"
        }
      },
      user: {
        serializedName: "user",
        type: {
          name: "String"
        }
      },
      password: {
        serializedName: "password",
        type: {
          name: "String"
        }
      },
      currentContractId: {
        serializedName: "currentContractId",
        type: {
          name: "Uuid"
        }
      },
      currentMainContractStartDate: {
        serializedName: "currentMainContractStartDate",
        type: {
          name: "DateTime"
        }
      },
      currentMainContractEndDate: {
        serializedName: "currentMainContractEndDate",
        type: {
          name: "DateTime"
        }
      }
    }
  }
};

export const VoivodeshipPaymentGroupInternalIdDto: msRest.CompositeMapper = {
  serializedName: "VoivodeshipPaymentGroupInternalIdDto",
  type: {
    name: "Composite",
    className: "VoivodeshipPaymentGroupInternalIdDto",
    modelProperties: {
      id: {
        serializedName: "id",
        type: {
          name: "Uuid"
        }
      },
      internalId: {
        serializedName: "internalId",
        type: {
          name: "Number"
        }
      },
      postingDate: {
        serializedName: "postingDate",
        type: {
          name: "DateTime"
        }
      }
    }
  }
};

export const VoivodeshipPaymentGroupDto: msRest.CompositeMapper = {
  serializedName: "VoivodeshipPaymentGroupDto",
  type: {
    name: "Composite",
    className: "VoivodeshipPaymentGroupDto",
    modelProperties: {
      id: {
        serializedName: "id",
        type: {
          name: "Uuid"
        }
      },
      internalId: {
        nullable: true,
        serializedName: "internalId",
        type: {
          name: "Number"
        }
      },
      voivodeshipId: {
        serializedName: "voivodeshipId",
        type: {
          name: "Number"
        }
      },
      year: {
        serializedName: "year",
        type: {
          name: "Number"
        }
      },
      month: {
        serializedName: "month",
        type: {
          name: "Number"
        }
      },
      amount: {
        serializedName: "amount",
        type: {
          name: "Number"
        }
      },
      voivodeshipPaymentState: {
        serializedName: "voivodeshipPaymentState",
        type: {
          name: "Enum",
          allowedValues: [
            "Created",
            "ReadyToSend",
            "SentToSap",
            "Paid",
            "Compensated"
          ]
        }
      },
      sentDate: {
        serializedName: "sentDate",
        type: {
          name: "DateTime"
        }
      },
      postingDate: {
        serializedName: "postingDate",
        type: {
          name: "DateTime"
        }
      },
      paymentDate: {
        serializedName: "paymentDate",
        type: {
          name: "DateTime"
        }
      },
      previousInternalIds: {
        serializedName: "previousInternalIds",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "VoivodeshipPaymentGroupInternalIdDto"
            }
          }
        }
      },
      hashCode: {
        serializedName: "hashCode",
        type: {
          name: "Number"
        }
      }
    }
  }
};

export const VoivodeshipPaymentGroupDtoPagedResult: msRest.CompositeMapper = {
  serializedName: "VoivodeshipPaymentGroupDtoPagedResult",
  type: {
    name: "Composite",
    className: "VoivodeshipPaymentGroupDtoPagedResult",
    modelProperties: {
      results: {
        serializedName: "results",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "VoivodeshipPaymentGroupDto"
            }
          }
        }
      },
      rowCount: {
        serializedName: "rowCount",
        type: {
          name: "Number"
        }
      },
      pageSize: {
        serializedName: "pageSize",
        type: {
          name: "Number"
        }
      },
      currentPage: {
        serializedName: "currentPage",
        type: {
          name: "Number"
        }
      },
      totalPagesNumber: {
        readOnly: true,
        serializedName: "totalPagesNumber",
        type: {
          name: "Number"
        }
      },
      hasPreviousPage: {
        readOnly: true,
        serializedName: "hasPreviousPage",
        type: {
          name: "Boolean"
        }
      },
      hasNextPage: {
        readOnly: true,
        serializedName: "hasNextPage",
        type: {
          name: "Boolean"
        }
      },
      firstRowOnPage: {
        readOnly: true,
        serializedName: "firstRowOnPage",
        type: {
          name: "Number"
        }
      },
      lastRowOnPage: {
        readOnly: true,
        serializedName: "lastRowOnPage",
        type: {
          name: "Number"
        }
      }
    }
  }
};
