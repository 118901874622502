import React, { useEffect, useRef, useState } from 'react';
import { Upload, Button, Tooltip, Popconfirm, message, Skeleton, notification } from 'antd';
import { UploadOutlined, DeleteOutlined } from '@ant-design/icons';
import type { UploadFile, UploadProps, ShowUploadListInterface } from 'antd/es/upload/interface';

export interface IAttachmentState {
    id?: string;
    uid: string;
    documentName?: string;
    file: File;
}

interface AttachmentUploaderProps {
    attachments: IAttachmentState[] | undefined;
    setAttachments: React.Dispatch<React.SetStateAction<IAttachmentState[] | undefined>>;
    editDisabled?: boolean;
    highlightSelectedItem: boolean;
    editMode?: boolean;
    setAttachment: (file: File) => void;
    allowedExtensions?: string[];
    maxFileSizeMB?: number;
}
const notificationBg = { backgroundColor: '#fbbdbd' };
const AttachmentUploader: React.FC<AttachmentUploaderProps> = (props: AttachmentUploaderProps) => {
    const [selectedAttachment, setSelectedAttachment] = useState<string | null>(null);
    const hasRunEffect = useRef(false);

    useEffect(() => {
        if (!hasRunEffect.current && props.attachments && props.attachments.length > 0) {
            setSelectedAttachment(props.attachments[0].uid);
            props.setAttachment(props.attachments[0].file)
            hasRunEffect.current = true;
        }
    }, [props.attachments]);

    const handleDelete = (file: IAttachmentState) => {
        props.setAttachments((prev = []) => prev.filter((item) => item.uid !== file.uid));
        hasRunEffect.current = false;
    };

    const getRandomInt = (min: number, max: number) => {
        return Math.floor(Math.random() * (max - min + 1)) + min;
    };

    const allowedExtensions = props.allowedExtensions || ['pdf', 'jpg', 'jpeg', 'png'];
    const maxFileSizeMB = props.maxFileSizeMB || 5;

    const isFileAllowed = (file: File): boolean => {
        const fileExtension = file.name.split('.').pop()?.toLowerCase();
        const isValidType = fileExtension ? allowedExtensions.includes(fileExtension) : false;
        if (!isValidType) {
            notification.error({
                placement: 'topRight',
                message: `You can only upload files with extensions: ${allowedExtensions.join(', ')}`,
                style: notificationBg,
                duration: 10
            });
        }
        const isLtMaxSize = file.size / 1024 / 1024 < maxFileSizeMB;
        if (!isLtMaxSize) {
            notification.error({
                placement: 'topRight',
                message: `File must be smaller than ${maxFileSizeMB}MB!`,
                style: notificationBg,
                duration: 10
            });
        }
        return isValidType && isLtMaxSize;
    };
    const uploadProps: UploadProps = {
        multiple: true,
        fileList: props.attachments?.map((attachment, index) => ({
            uid: attachment.uid,
            name: `${index + 1}. ${attachment.file.name}`,
            originFileObj: attachment.file,
        } as UploadFile)),
        beforeUpload: (file: File): Promise<void> => {
            return new Promise((resolve, reject) => {
                if (!isFileAllowed(file)) {
                    reject();
                    return;
                }
                const newFile = new File([file], file.name, { type: file.type });
                const newAttachment = { uid: getRandomInt(1, 1000000).toString(), file: newFile };
                props.setAttachments((prev = []) => [...prev, newAttachment]);
                reject(false);
            });
        },
        itemRender: (originNode, file, currFileList) => {
            const isSelected = selectedAttachment === file.uid;
            const customStyle = (isSelected && props.highlightSelectedItem) ? { backgroundColor: '#e6f7ff' } : {};
            return (
                <div
                    style={{ ...customStyle, cursor: 'pointer' }}
                    onClick={() => {
                        setSelectedAttachment(file.uid);
                    }}
                >
                    {originNode}
                </div>
            );
        },
        showUploadList: {
            showRemoveIcon: true,
            removeIcon: (file: UploadFile) => (
                <Popconfirm
                    title="Are you sure to delete attachment?"
                    okText="Yes"
                    cancelText="No"
                    placement="bottom"
                    onConfirm={() => handleDelete({ uid: file.uid, file: file.originFileObj as File })}
                >
                    <Tooltip title="Delete attachment">
                        <DeleteOutlined />
                    </Tooltip>
                </Popconfirm>
            ),
        } as ShowUploadListInterface,
    };

    return (
        <>
            {(uploadProps.fileList != undefined && (props.editMode === true || props.editMode === false)) || props.editMode === undefined ? (
                <Upload
                    {...uploadProps}
                    style={{ cursor: 'pointer' }}
                    listType="text"
                    onPreview={(attachment: UploadFile<IAttachmentState>) => attachment.originFileObj && props.setAttachment(attachment.originFileObj as File)}
                    accept={allowedExtensions.map(ext => `.${ext}`).join(',')}
                    disabled={props.editDisabled}
                >
                    <Button
                        type="primary"
                        size="middle"
                        icon={<UploadOutlined />}
                        disabled={props.editDisabled}
                        shape="round"
                        style={{ width: '100%' }}
                    >
                        Add Attachments
                    </Button>
                </Upload>
            ) : (
                <Skeleton active />
            )}
        </>
    );
};

export default AttachmentUploader;
